
import { DivWithNormalScreen, DivWithFullScreen } from "../components/models/page-props";
import { isInstanceOfWellsAndPermitsObject } from "../components/common/Map/helper"
import { LineChartDataObj, LineChartDateAggObj, LineChartMonAggObj } from "../components/models/redux-models";
import { WellsAndPermitsObject, RigsDataObj, ProductionDataObj } from "../components/models/redux-models";
import { ForecastingFormObj } from "../components/models/redux-models";
import { ActionType } from "../components/models/redux-models";
export const enterFullScreen = (targetdiv: DivWithNormalScreen) => {
    if (targetdiv.requestFullscreen) targetdiv.requestFullscreen();
    else if (targetdiv.mozRequestFullScreen) targetdiv.mozRequestFullScreen();
    else if (targetdiv.webkitRequestFullscreen) targetdiv.webkitRequestFullscreen();
    else if (targetdiv.msRequestFullscreen) targetdiv.msRequestFullscreen();
}

export const exitFullScreen = () => {
    const doc = document as DivWithFullScreen;
    if (doc.fullscreen) {
        if (doc.exitFullscreen) doc.exitFullscreen();
        else if (doc.mozCancelFullScreen) doc.mozCancelFullScreen();
        else if (doc.webkitExitFullscreen) doc.webkitExitFullscreen();
        else if (doc.msExitFullscreen) doc.msExitFullscreen();
    }
}
export type OriginalDataValue = {
    production_date?: string;
    production_qty: number;
    production_month?: number;
}
type OriginalData = {
    name: string;
    values: OriginalDataValue[]
};
export const getLineChartData = (
    dataList: {
        name: string;
        values: {
            production_date?: string;
            production_qty: number;
            production_month?: number;
        }[];
    }[] | null,
    normalized: boolean,
    type: "monthlyTab" | "cumTab",
    prodLiq: LineChartDataObj[],
    prodGas: LineChartDataObj[],
    gasAggDate: LineChartDateAggObj[],
    gasAggMonth: LineChartMonAggObj[],
    liqAggDate: LineChartDateAggObj[],
    liqAggMonth: LineChartMonAggObj[],
    usedFor: string,
    OIL: string,
    GAS: string,
    selectedWellRigOrPermitList: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[],
    fullScrnAnalytics: boolean,
    useDateXAxis: boolean,
    action: ActionType,
    apiList: string[],
    selectedForecastPoints: ForecastingFormObj[] | null) => {

    type TempDataArrayType = {
        name: string;
        values: {
            production_date?: string;
            production_month?: number;
            production_qty: number;
            highlight: boolean
        },
        api?:string
    }
    // this is the forecasting date
    let forecastingData: OriginalData[] = dataList
        ? [...dataList.map((item: any) => Object.assign({}, item))]
        : [];

    let tempDataArray: TempDataArrayType[] = []


    const wellNames: { [key: string]: string } = {}
    selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
        if (isInstanceOfWellsAndPermitsObject(well)) {
            wellNames[well.well_api] = well.well_name
        }
    })

    const getHighlightForLineChart = (index: number) => {
        if (!fullScrnAnalytics || !selectedForecastPoints) {
            return true
        }
        if (selectedForecastPoints?.map((forecastPoint: ForecastingFormObj) => forecastPoint.index).includes(index)) {
            return true
        }
        return false
    }

    const reprocessDataForLineChart = (data: any, useDateXAxis: boolean, prodQtyStr: string, tempDataArray: TempDataArrayType[]) => {

        if (!action || action === "none") {
            apiList.forEach((api: string) => {
                const tempValues = data.filter((row: any) => row.api_id.toString() === api).map((item: any, index: number) => {
                    if (useDateXAxis) {
                        return {
                            production_date: item.production_date,
                            production_qty: item[prodQtyStr],
                            highlight: getHighlightForLineChart(index)
                        }
                    }
                    return {
                        production_month: item.production_month,
                        production_qty: item[prodQtyStr],
                        highlight: getHighlightForLineChart(index)
                    }

                })
                tempDataArray.push({ name: wellNames[api] || api, values: tempValues, api })
            })
        } else {
            const tempValues = data.map((item: any, index: number) => {
                if (useDateXAxis) {
                    return {
                        production_date: item.production_date,
                        production_qty: item[prodQtyStr],
                        highlight: getHighlightForLineChart(index)
                    }
                }
                return {
                    production_month: item.production_month,
                    production_qty: item[prodQtyStr],
                    highlight: getHighlightForLineChart(index)
                }

            })
            tempDataArray.push({ name: action, values: tempValues })
        }

        return tempDataArray
    }
    const getActionString = (action: string | undefined, normalized: boolean, type: string) => {
        if (!action) {
            return ''
        }
        if (type === "monthlyTab" && !normalized) {
            if (action.toLowerCase() === 'average') {
                return 'production_qty_mean'
            } else if (action.toLowerCase() === "sum") {
                return 'production_qty_sum'
            } else if (action.toLowerCase() === "p10") {
                return 'production_qty_p10'
            } else if (action.toLowerCase() === "p50") {
                return 'production_qty_p50'
            } else if (action.toLowerCase() === "p90") {
                return 'production_qty_p90'
            }
        } else if (type === "monthlyTab") {
            if (action.toLowerCase() === 'average') {
                return 'production_qty_norm_mean'
            } else if (action.toLowerCase() === "sum") {
                return 'production_qty_norm_sum'
            } else if (action.toLowerCase() === "p10") {
                return 'production_qty_norm_p10'
            } else if (action.toLowerCase() === "p50") {
                return 'production_qty_norm_p50'
            } else if (action.toLowerCase() === "p90") {
                return 'production_qty_norm_p90'
            }
        } else if (type === "cumTab" && !normalized) {
            if (action.toLowerCase() === 'average') {
                return 'cum_production_qty_mean'
            } else if (action.toLowerCase() === "sum") {
                return 'cum_production_qty_sum'
            } else if (action.toLowerCase() === "p10") {
                return 'cum_production_qty_p10'
            } else if (action.toLowerCase() === "p50") {
                return 'cum_production_qty_p50'
            } else if (action.toLowerCase() === "p90") {
                return 'cum_production_qty_p90'
            }
        } else if (type === "cumTab") {
            if (action.toLowerCase() === 'average') {
                return 'cum_production_qty_norm_mean'
            } else if (action.toLowerCase() === "sum") {
                return 'cum_production_qty_norm_sum'
            } else if (action.toLowerCase() === "p10") {
                return 'cum_production_qty_norm_p10'
            } else if (action.toLowerCase() === "p50") {
                return 'cum_production_qty_norm_p50'
            } else if (action.toLowerCase() === "p90") {
                return 'cum_production_qty_norm_p90'
            }
        }
        return ''

    }

    /**If this is an oil chart and its not normalized
     * and its not cumulative and month and no action  */
    if (usedFor === OIL && !normalized && type === "monthlyTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodLiq, useDateXAxis, "production_qty", tempDataArray)
    }

    /**If this is an oil chart and its normalized
       * and its not cumulative and month and no action  */
    if (usedFor === OIL && normalized && type === "monthlyTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodLiq, useDateXAxis, "production_qty_norm", tempDataArray)
    }

    /**If this is an oil chart and its not normalized
     * and its cumulative and month and no action  */
    if (usedFor === OIL && !normalized && type === "cumTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodLiq, useDateXAxis, "cum_production_qty", tempDataArray)
    }

    /**If this is an oil chart and its normalized
       * and its cumulative and no action  */
    if (usedFor === OIL && normalized && type === "cumTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodLiq, useDateXAxis, "cum_production_qty_norm", tempDataArray)
    }

    /**If this is an gas chart and its not normalized
     * and its not cumulative and no action  */
    if (usedFor === GAS && !normalized && type === "monthlyTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodGas, useDateXAxis, "production_qty", tempDataArray)
    }

    /**If this is an gas chart and its normalized
       * and its not cumulative and no action  */
    if (usedFor === GAS && normalized && type === "monthlyTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodGas, useDateXAxis, "production_qty_norm", tempDataArray)
    }

    /**If this is an gas chart and its not normalized
     * and its cumulative and no action  */
    if (usedFor === GAS && !normalized && type === "cumTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodGas, useDateXAxis, "cum_production_qty", tempDataArray)
    }

    /**If this is an gas chart and its normalized
       * and its cumulative and no action  */
    if (usedFor === GAS && normalized && type === "cumTab" && (!action || action === "none")) {
        tempDataArray = reprocessDataForLineChart(prodGas, useDateXAxis, "cum_production_qty_norm", tempDataArray)
    }

    // HAS AN ACTION

    /**If this is an oil chart and its not normalized
     * and its not cumulative and month and no action  */
    if (usedFor === OIL && !normalized && type === "monthlyTab" && (action && action !== "none")) {
        console.log('here', useDateXAxis ? liqAggDate : liqAggMonth)
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? liqAggDate : liqAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an oil chart and its normalized
       * and its not cumulative and month and no action  */
    if (usedFor === OIL && normalized && type === "monthlyTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? liqAggDate : liqAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an oil chart and its not normalized
     * and its cumulative and month and no action  */
    if (usedFor === OIL && !normalized && type === "cumTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? liqAggDate : liqAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an oil chart and its normalized
       * and its cumulative and no action  */
    if (usedFor === OIL && normalized && type === "cumTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? liqAggDate : liqAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an gas chart and its not normalized
     * and its not cumulative and no action  */
    if (usedFor === GAS && !normalized && type === "monthlyTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? gasAggDate : gasAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an gas chart and its normalized
       * and its not cumulative and no action  */
    if (usedFor === GAS && normalized && type === "monthlyTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? gasAggDate : gasAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an gas chart and its not normalized
     * and its cumulative and no action  */
    if (usedFor === GAS && !normalized && type === "cumTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? gasAggDate : gasAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }

    /**If this is an gas chart and its normalized
       * and its cumulative and no action  */
    if (usedFor === GAS && normalized && type === "cumTab" && (action && action !== "none")) {
        tempDataArray = reprocessDataForLineChart(useDateXAxis ? gasAggDate : gasAggMonth, useDateXAxis, getActionString(action, normalized, type), tempDataArray)
    }
    return [...tempDataArray, ...forecastingData];
}


type MergedData = {
    name: string;
    values: { date: any, production_quantity_oil: number | null, production_quantity_gas: number | null }[]
};

export const mergeAndSortOilGasData = (oilData: OriginalData[], gasData: OriginalData[], producingTime: boolean, action: string): MergedData[] => {
    const mergedData: MergedData[] = [];

    const wellMap = new Map<string, MergedData>();
    function toFixedIfNecessary(value: number | null, dp: number) {
        if (!value) {
            return value
        }
        return +parseFloat(value.toString()).toFixed(dp);
    }
    // Process oilData
    oilData.forEach(oilWell => {
        const mergedWellData: MergedData = {
            name: action && action.toLowerCase() !== "none" ? action : oilWell.name,
            values: oilWell.values.map(oilValue => ({
                date: producingTime ? oilValue.production_month?.toString() : oilValue.production_date,
                production_quantity_oil: toFixedIfNecessary(oilValue.production_qty, 2),
                production_quantity_gas: null,
            }))
        };
        wellMap.set(oilWell.name, mergedWellData);
    });
    console.log('the original oil data and merge is ', oilData, mergedData)
    // Process gasData
    gasData.forEach(gasWell => {
        if (wellMap.has(gasWell.name)) {
            const mergedWell = wellMap.get(action && action.toLowerCase() !== "none" ? action : gasWell.name)!;
            gasWell.values.forEach(gasValue => {
                const existingEntry = mergedWell.values.find(value => producingTime ? value.date === gasValue.production_month?.toString() : value.date === gasValue.production_date);
                if (existingEntry) {
                    existingEntry.production_quantity_gas = toFixedIfNecessary(gasValue.production_qty, 2);
                } else {
                    mergedWell.values.push({
                        date: producingTime ? gasValue.production_month?.toString() : gasValue.production_date,
                        production_quantity_oil: null,
                        production_quantity_gas: toFixedIfNecessary(gasValue.production_qty, 2),
                    });
                }
            });
        } else {
            const newWellData: MergedData = {
                name: gasWell.name,
                values: gasWell.values.map(gasValue => ({
                    date: gasValue.production_date,
                    production_quantity_oil: null,
                    production_quantity_gas: toFixedIfNecessary(gasValue.production_qty, 2),
                }))
            };
            wellMap.set(gasWell.name, newWellData);
        }
    });

    // Convert the Map to an array and sort the values by date for each well
    if (producingTime) {
        wellMap.forEach(mergedWell => {
            mergedWell.values.sort((a, b) => a.date - b.date);
            mergedData.push(mergedWell);
        });
    } else {
        wellMap.forEach(mergedWell => {
            mergedWell.values.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            mergedData.push(mergedWell);
        });
    }


    return mergedData;
}

export const combineListOfStringsIntoOne = (listOfString: string[]) => {
    return listOfString.join('')
}

export const getNameInitials = (name: string) => {
    return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase())
        .join('');
}
