import React, { useLayoutEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../components/hooks/redux-hooks";
import Spinner2 from "../components/common/Spinner2";

const PublicRoute = () => {
    const subscription_status = useAppSelector((state) => state.auth.user.subscription_status);
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

    const [state, setState] = useState<boolean | null>();

    useLayoutEffect(() => {
        setState(isAuthenticated);
    }, [isAuthenticated]);

    if (state == null) {
        return <Spinner2 />;
    }

    return state ? subscription_status && subscription_status === "active" ? <Navigate to="/search" /> : <Navigate to="/cart-select-basin" /> : <Outlet />;
};

export default PublicRoute;
