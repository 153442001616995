import { AnyAction } from "redux";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import cartBasinToFilterSlice from "../reducers/cart-basin-to-filter-slice";
import { csvApiDataObj, BasinObjApiList, CountyObjApiList } from "../../models/submit-form"
import { CartBasinFilterState, CartBasinFilterFormData } from "../../models/page-props"

export const cartBasinToFilterActions = cartBasinToFilterSlice.actions;

export const setCartBasinFilterState = (
    initialState: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setInitialState(initialState))
    }
}

export const setCsvApiFileLoading = (
    loading: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setCsvApiFileLoading(loading))
    }
}

export const setCsvApiFileData = (
    csvApiFileData: csvApiDataObj[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setCsvApiFileData(csvApiFileData))
    }
}

export const setNotInPlan = (
    notInPlan: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setNotInPlan(notInPlan))
    }
}

export const setByBasinInTabData = (
    byBasinInTabData: BasinObjApiList[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setByBasinInTabData(byBasinInTabData))
    }
}

export const setByCountyInTabData = (
    byCountyInTabData: CountyObjApiList[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setByCountyInTabData(byCountyInTabData))
    }
}

export const setCsvApiFileName = (
    csvApiFileName: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setCsvApiFileName(csvApiFileName))
    }
}

export const setCsvApiFileSize = (
    csvApiFileSize: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setCsvApiFileSize(csvApiFileSize))
    }
}

export const setCsvApiFile = (
    csvApiFile: File | null
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setCsvApiFile(csvApiFile))
    }
}

export const setFileToOpen = (
    csvApiFileToOpen: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setFileToOpen(csvApiFileToOpen))
    }
}

export const setOpenModalAFterUploadModal = (
    openModalAFterUploadModal: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setOpenModalAFterUploadModal(openModalAFterUploadModal))
    }
}

export const setGeometry = (
    geo: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setGeometry(geo))
    }
}

export const setEpsg = (
    epsg: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setEpsg(epsg))
    }
}

export const setFormData = (
    formData: CartBasinFilterFormData | null
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setFormData(formData))
    }
}

export const setDeleteItemId = (
    deleteItemId: number | null
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setDeleteItemId(deleteItemId))
    }
}

export const setDeleteCartItemModal = (
    deleteCartItemId: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setDeleteCartItemModal(deleteCartItemId))
    }
}

export const setDeleteCartItemType = (
    deleteCartItemType: number | null
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setDeleteCartItemType(deleteCartItemType))
    }
}
export const setApiFileWellApiList = (
    apiFileWellApiList: string[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setApiFileWellApiList(apiFileWellApiList))
    }
}

export const setShapeFileModal = (
    shapeFileModal: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setShapeFileModal(shapeFileModal))
    }
}

export const setIsChooseColumn = (
    isChooseColumn: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setIsChooseColumn(isChooseColumn))
    }
}

export const setIsExportOther = (
    isExportOther: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToFilterActions.setIsExportOther(isExportOther))
    }
}
