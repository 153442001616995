import { Fragment } from "react";
import { cartBasinBubbleProps } from "../models/page-props";
import { Tooltip } from 'react-tooltip'

const CartBasinBubble = (props: cartBasinBubbleProps) => {
    const { label, bubbleType, handleBubbleType, tooltipHTML } = props
    return (
        <div className="form-group">
            <div className="row-point-5-gap">
                {tooltipHTML ? (<i data-tooltip-id={label.replace(/\s/g, '')}
                    data-tooltip-html={tooltipHTML}
                    data-tooltip-place="top"
                    data-tooltip-variant="light">
                    <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                </i>) : ''}

                <label htmlFor="">{label}</label>
            </div>
            <ul className="types">
                {
                    bubbleType.length > 0 && bubbleType.map(({ id, title, active }, index) =>
                        <Fragment key={index}>
                            <li onClick={() => handleBubbleType(id)} className={active ? 'active' : ""}>{title}</li>
                        </Fragment>
                    )
                }
            </ul>
            {tooltipHTML ? <Tooltip id={label.replace(/\s/g, '')} /> : ''}
        </div>
    )
};

export default CartBasinBubble;
