import React, { useEffect } from "react";
import Headers from "../../components/common/Headers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../components/hooks/redux-hooks";
import { fetchPrivacyPolicy } from "../../components/store/actions/auth-actions";
import parse from "html-react-parser";

export default function Privacy() {
  const privacyPolicy = useAppSelector((state) => state.auth.privacyPolicy);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!privacyPolicy) {
      dispatch(fetchPrivacyPolicy());
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Headers />
      <div className="wapper">
        <div className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="privacy-policy-content">
                  <h1 className="text-center mb-40">
                    Energy Domain Data Privacy Policy
                  </h1>
                  {parse(privacyPolicy || "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
