import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import aoiSlice from "../reducers/aoi-slice";
import {
    actionType,
    config,
    errToast,
    tokenIsValid,
} from "../../../utils/helper";
import axios from "../../../utils/axios";
import { AxiosError } from "axios";
import { hideSiteLoader, showSiteLoader } from "./modal-actions";
import { toast } from "react-toastify";
import {
    ReturnMsgAndStatus,
    SendCrsReturnType,
    UploadAoiFileReturnType,
    fetchCrsListReturnType,
} from "../../models/submit-form";
import { AoiModel, AoiNotiDataType } from "../../models/redux-models";
import { logUserAction } from "./auth-actions";
import { File } from "../../models/redux-models";
import { toggleLeftTab } from "./cart-basin-to-county-actions";
import { handleSelectedAoiData } from "./wells-rigs-action";
import { handleAoiALertEnabled } from "./alert-actions";

export const aoiActions = aoiSlice.actions;

export const toggleSettDrawer = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.toggleSettDrawer());
    };
};

//fetch upload aoi file
export const fetchAoiStats = (
    token: string,
    formData: { geometry: string }
): ThunkAction<
    Promise<UploadAoiFileReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        const {
            wellsAndRigs: {
                selectedAoiData: { aoi_id },
            },
        } = getState();
        if (aoi_id) {
            dispatch(handleSelectedAoiData({ aoi_id: 0 }));
        }

        try {
            const res = await axios.post(`/api-aoi/aoi-stats`, formData);
            const { status, msg } = res.data;
            if (status !== 200 && status !== 422) toast.error(msg);
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//upload upload aoi file
export const uploadAoiFile = (
    token: string,
    formData: {
        file: File;
        aoi_name: string;
        file_name: string;
        buffer_distance: string;
    }
): ThunkAction<
    Promise<UploadAoiFileReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        const { file, aoi_name, file_name, buffer_distance } = formData;
        const fileType = (file as File).name.split(".").pop();
        const data = new FormData();
        data.append("file", file, file.name);

        try {
            axios.defaults.headers.common[
                "Content-Disposition"
            ] = `attachment; filename=${file.name}`;
            const res = await axios.put(
                `/api-aoi/upload-aoi-file?aoi_name=${aoi_name}${
                    buffer_distance ? `&buffer_distance=${buffer_distance}` : ""
                }${file_name ? `&file_name=${file_name}` : ""}`,
                data
            );
            const { status, msg } = res.data;
            if (status === 200) {
                //log save aoi file
                dispatch(
                    logUserAction({
                        action_type: actionType["save_aoi"],
                        // action_log_detail: `aoi_name: ${aoi_name}, buffer_distance: ${formData.buffer_distance}`,
                        action_log_detail: JSON.stringify({
                            aoi_name: formData.aoi_name,
                            buffer_distance: formData.buffer_distance,
                        }),
                    })
                );
                dispatch(setLastAddedAoiName(formData.aoi_name));
            }
            if (status === 200) {
                toast.success(msg);
            } else {
                if (status !== 422) toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//get aoi file
export const fetchAoiList = (
    token: string,
    showLoader = true
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        showLoader && dispatch(showSiteLoader());
        await tokenIsValid(token);

        try {
            const res = await axios.get(`/api-aoi/upload-aoi-file`);
            const { status, data, msg, max_allowed_aoi } = res.data;
            if (status === 200) {
                if (!("data" in res.data)) {
                    dispatch(
                        aoiActions.fetchAoiList({ data: [], max_allowed_aoi })
                    );
                    return;
                }
                if (Array.isArray(data)) {
                    dispatch(
                        aoiActions.fetchAoiList({ data, max_allowed_aoi })
                    );
                }
            } else {
                toast.error(msg);
            }
            showLoader && dispatch(hideSiteLoader());
        } catch (err) {
            errToast(err as AxiosError);
            showLoader && dispatch(hideSiteLoader());
        }
    };
};

//crsModal
export const clearAoiList = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.clearAoiList());
    };
};

//send crs
export const sendCrs = (
    token: string,
    formData: { crs?: string; file_name: string; aoi_name: string }
): ThunkAction<Promise<SendCrsReturnType>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);

        try {
            const res = await axios.post(`/api-aoi/set-crs`, formData, config);
            const {
                data: {
                    data: { file_name },
                },
            } = res.data;
            if (res.data.status === 200) {
                //log user actions
                dispatch(
                    logUserAction({
                        action_type: actionType["upload_shapefile"],
                        action_log_detail: JSON.stringify({ file_name }),
                    })
                );
            }

            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//aoi name selected for edit
export const setAoiNameSelForEdit = (data: {
    aoi_name: string;
    aoi_id: number;
    buffer_distance?: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.setAoiNameSelForEdit(data));
    };
};

//aoi name selected for last add
export const setLastAddedAoiName = (
    aoi_name: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.setLastAddedAoiName(aoi_name));
    };
};

//clear aoi name selected for edit
export const clearAoiNameSelForEdit = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.clearAoiNameSelForEdit());
    };
};

//crs options
export const fetchCrsList =
    (
        token: string,
        data: { search: string; page: number }
    ): ThunkAction<
        Promise<fetchCrsListReturnType>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch) => {
            const { search, page } = data;
            await tokenIsValid(token);
            try {
                const response = await axios.get(
                    `/api-setting/crs-lookup?param=${search}&page=${page}`,
                    config
                );
                const { status, msg } = response.data;
                if (status !== 200) toast.error(msg);
                return response.data;
            } catch (err) {
                errToast(err as AxiosError);
            }
        };

//setGenTabAoiNotiData
export const setAoiGenTabNotiData = (
    data: AoiModel["aoiGenTabNotiData"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.setAoiGenTabNotiData(data));
    };
};

//setAoiNotiData
export const setAoiNotiData = (
    data: AoiModel["aoiNotiData"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.setAoiNotiData(data));
    };
};

//get aoi notification
export const getAoiNotiData = (
    token: string,
    // note:- if aoi_id is passed it will used for particular aoi notification settings
    aoi_id?: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);

        try {
            const res = await axios.get(
                `/api-aoi/aoi-notification${aoi_id ? `?aoi_id=${aoi_id}` : ""}`,
                config
            );
            const { data, status, msg, is_aoi_alert_enabled } = res.data;
            if (status === 200 && data) {
                if (aoi_id) {
                    dispatch(
                        setAoiNotiData(
                            is_aoi_alert_enabled
                                ? data
                                : data.map((item: AoiNotiDataType) => ({
                                      ...item,
                                      is_email: false,
                                      is_in_app: false,
                                  }))
                        )
                    );
                    dispatch(handleAoiALertEnabled(is_aoi_alert_enabled));
                } else {
                    dispatch(setAoiGenTabNotiData(data));
                }
            } else {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handle aoi-tab index
export const handleAoiTabIndex = (
    index: AoiModel["aoi_tab_index"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.handleAoiTabIndex(index));
    };
};

//update AOI Name
export const updateAoiData = (
    token: string,
    formData: {
        aoi_name?: string;
        aoi_id: number;
        buffer_distance?: number;
    }
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);

        try {
            const res = await axios.put(
                "aoi_name" in formData
                    ? `api-aoi/update-aoi_name`
                    : "/api-aoi/update-buffer-distance",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//update aoi Notificatio settings
export const updateAoiNotiData = (
    token: string,
    formData:
        | { notification_settings: AoiModel["aoiGenTabNotiData"] }
        | { notification_settings: AoiModel["aoiNotiData"] },
    // if aoi id is passed it will used to update particular aoi notification settings
    aoi_id?: number
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);

        try {
            // eslint-disable-next-line
            const res = await axios.put(
                `/api-aoi/aoi-notification${aoi_id ? `?aoi_id=${aoi_id}` : ""}`,
                formData,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//clearAoiNotiData
export const clearAoiNotiData = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.clearAoiNotiData());
    };
};

//clearGenTabAoiNotiData
export const clearAoiGenTabNotiData = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.clearAoiGenTabNotiData());
    };
};

//save upload aoi file using Drawing on map
export const uploadAoiUsingMapFile = (
    token: string,
    formData: {
        aoi_name: string;
        buffer_distance: number;
        crs: number;
        geometry: {
            type: string;
            properties: { id: number };
            geometry: {
                type: string;
                coordinates: any;
            };
        }[];
    }
): ThunkAction<
    Promise<
        ReturnMsgAndStatus & {
            data: {
                img_name: string;
                multi_polygon: string;
                epsg: string;
                file_name: string;
                buffer_distance: number;
                aoi_id: number;
            };
        }
    >,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.post(`/api-aoi/create-aoi`, formData);
            const {
                status,
                msg,
                data: { file_name },
            } = res.data;
            dispatch(hideSiteLoader());
            if (status === 200) {
                //log save aoi file
                dispatch(
                    logUserAction({
                        action_type: actionType["save_aoi"],
                        action_log_detail: JSON.stringify({
                            file_name,
                        }),
                    })
                );
                // toast.success(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handle aoi-tab index
export const handleUsingMapCreateAoi = (
    val: AoiModel["usingMapCreateAoi"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(aoiActions.handleUsingMapCreateAoi(val));
    };
};

//Delete aoi
export const removeAoi = (
    token: string,
    aoiId: number
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const config = {
                data: { id: aoiId },
                headers: { "Content-Type": "application/json" },
            };
            const res = await axios.delete("/api-aoi/create-aoi", config);
            const { msg, status } = res.data;

            dispatch(hideSiteLoader());
            if (status === 200) {
                //log delete aoi
                dispatch(
                    logUserAction({
                        action_type: actionType["delete_aoi"],
                        action_log_detail: `aoi_id: ${aoiId}`,
                    })
                );
                toast.success(msg);
            } else {
                status !== 200 && toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};


//check duplicate aoi name
export const checkDuplicateAOIName =
    (
        token: string,
        data: { aoi_name: string },
        hideSiteLoaderFlag?: boolean
    ): ThunkAction<
        Promise<ReturnMsgAndStatus>,
        RootState,
        unknown,
        AnyAction
    > =>
    async (dispatch) => {
        await tokenIsValid(token);
        dispatch(showSiteLoader());
        try {
            const response = await axios.get(
                `/api-aoi/check-name?aoi_name=${data["aoi_name"]}`,
                config
            );
            const { status, msg } = response.data;
            if (status === 442) {
                toast.error(msg);
                hideSiteLoaderFlag && dispatch(hideSiteLoader());
            }
            !hideSiteLoaderFlag && dispatch(hideSiteLoader());
            return response.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };

//check duplicate aoi name
export const getDefaultAOIName =
    (): ThunkAction<
        Promise<ReturnMsgAndStatus & { aoi_name: string }>,
        RootState,
        unknown,
        AnyAction
    > =>
    async (dispatch, getState) => {
        dispatch(showSiteLoader());
        const {
            auth: {
                user: { access_token },
            },
        } = getState();
        await tokenIsValid(access_token);
        try {
            const response = await axios.get(`/api-aoi/default-name`, config);
            const { status, msg } = response.data;
            if (status === 442) {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return response.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
