import React, { useMemo } from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import Scrollbars from 'react-custom-scrollbars';
import { USDollar } from "../../../utils/helper";

function InventoryLineItemTable() {
    const yearly = useAppSelector(state => state.cartSelectBasinCounty.yearly);
    const yearlyDiscountPercent = useAppSelector(state => state.cartSelectBasinCounty.yearlyDiscountPercent);
    const invoiceLineItems = useAppSelector(state => state.cartSelectBasinCounty.invoiceLineItems);


    const yearlyDiscountFraction = useMemo(() => {
        return (100 - yearlyDiscountPercent) / 100
    }, [yearlyDiscountPercent])


    return (<Scrollbars
        className='segmentsSection-scroll'
        autoHeight
        autoHeightMin={'25rem'}
        renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
        )}
        renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
        )}
    >
        <table className="invoice-line-item-table">
            <thead>
                <tr>
                    <th className="invoice-head-left-align">Description</th>
                    <th >Qty</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {invoiceLineItems && invoiceLineItems.map((item, index) => (
                    <tr key={index}>
                        <td className="invoice-body-left-align">{item.description}</td>
                        <td>{item.quantity}</td>
                        <td>
                            {USDollar.format(yearly ? item.unit_amount_excluding_tax / yearlyDiscountFraction : item.unit_amount_excluding_tax)}
                        </td>
                        <td>
                            {USDollar.format(yearly ? item.amount / yearlyDiscountFraction : item.amount)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </Scrollbars>
    );
}

export default InventoryLineItemTable;
