import React, { useEffect, useLayoutEffect } from "react";
import withSideNav from "../HOC/withSideNav";
import SideContainer from "./SideContainer";
import CommonMainContent from "./CommonMainContent";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    clearProductionData,
    clearRigsData,
    clearWellsData,
    fetchColumnProperties,
    handlePageChange,
    handleResizableHeight,
    handleSelectedAoiData,
    handleSelectedWellRigOrPermitUIDList,
    setRapidAPIFromInput,
    toggleViewAnalytics
} from "../store/actions/wells-rigs-action";
import { toggleLeftTab } from "../store/actions/cart-basin-to-county-actions";
import { useLocation } from "react-router-dom";
import {
    aoiPathname,
    cartSelectBasin,
    saveFiltersPathname,
    searchPathname
} from "../../utils/helper";
import { resetApiList, resetShapeFileList } from "../store/actions/files-actions";
import {
    clearAoiList,
    setAoiNameSelForEdit,
    toggleSettDrawer
} from "../store/actions/aoi-actions";
import { handleUpgradeSubscription } from "../store/actions/subscription-settings-actions";

const CommonView = () => {

    const wellsAndRigs = useAppSelector((state) => state.wellsAndRigs);
    const cartBasinToCounty = useAppSelector((state) => state.cartBasinToCounty);
    const files = useAppSelector((state) => state.files);
    const aoi = useAppSelector((state) => state.aoi);
    const subscriptionSettings = useAppSelector((state) => state.subscriptionSettings);


    const dispatch = useAppDispatch();
    const location = useLocation();

    useLayoutEffect(() => {
        //open the left tab when path changes
        dispatch(toggleLeftTab(true))
        //  For search page
        wellsAndRigs.viewAnalytics && location.pathname === searchPathname && dispatch(toggleViewAnalytics());
        wellsAndRigs.loadColumnProperties && (location.pathname === searchPathname || location.pathname === aoiPathname) && dispatch(fetchColumnProperties());
        if (wellsAndRigs.selectedAoiData.aoi_id && (location.pathname === searchPathname || location.pathname === aoiPathname)) {
            //closing setting drawer if it is open
            if (aoi.toggleSettingDrawer) {
                aoi.toggleSettingDrawer && dispatch(toggleSettDrawer());
                dispatch(
                    setAoiNameSelForEdit({ aoi_id: 0, aoi_name: '' })
                );
            }
            dispatch(handleSelectedAoiData({ aoi_id: 0 }));
            dispatch(handleSelectedWellRigOrPermitUIDList([]));
            dispatch(handlePageChange(1));
            dispatch(clearProductionData());
            dispatch(clearWellsData());
            dispatch(clearRigsData());
        }

        return () => {
            //  For search page
            if (location.pathname === searchPathname) {
                wellsAndRigs.viewAnalytics && dispatch(toggleViewAnalytics());
                dispatch(toggleLeftTab(true))
            }
            if (location.pathname !== searchPathname) {
                wellsAndRigs.rapidAPIFromInput && dispatch(setRapidAPIFromInput(""))
            }
            // if (location.pathname === aoiPathname) {
            !files.shapeFileListLoading && dispatch(resetShapeFileList())
            !files.apiListLoading && dispatch(resetApiList())
            !aoi.aoiDataLoading && dispatch(clearAoiList())
            dispatch(handleResizableHeight(`17rem`));

            // }
            if (location.pathname === cartSelectBasin && subscriptionSettings.upgrade_subscription) {
                dispatch(handleUpgradeSubscription(false))
            }
        }
        // eslint-disable-next-line
    }, [location.pathname])

    useEffect(() => {
        let myElement = document.getElementById("filterToggleMove");
        if (myElement) { // Ensure myElement is not null
            if (!cartBasinToCounty.leftTab && location.pathname === searchPathname) {
                myElement.classList.add(`${cartBasinToCounty.showAdvancedFilter ? "advancedfilterToggle" : "filterToggle"}`);
                myElement.classList.remove("resetcart-map");
            } else {
                myElement.classList.remove(`${cartBasinToCounty.showAdvancedFilter ? "advancedfilterToggle" : "filterToggle"}`);
                location.pathname === searchPathname ? myElement.classList.add("resetcart-map") : myElement.classList.remove("resetcart-map");
            }
        }
    }, [cartBasinToCounty.leftTab, location.pathname])
    return (
        <>
            <SideContainer />
            {location.pathname !== saveFiltersPathname ?
                <CommonMainContent /> : <></>}

        </>
    );
};

export default withSideNav(CommonView, true, true);
