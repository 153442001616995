import React, { useState, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    handleAnalyticsProdFilters, handleAnalyticsTabIndex,
    handleForecast,
    handleFullScrnAnalytics, handleNormalize,
    handleShowAndHideTable, toggleViewAnalytics
} from "../store/actions/wells-rigs-action";
import LineChart from "../common/LineChart";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, GAS, LINE_CHART_MAX_ITEM_ALLOWED, ANALYTICS_MONTHLY_TAB, OIL,
    LINE_CHART_XAXIS_FILTERS
} from "../../utils/helper";
import { toast } from "react-toastify";
import { Tooltip } from 'react-tooltip'
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import DownloadProductionLink from "./DownloadProductionLink"
// import { combineListOfStringsIntoOne } from "../../Helper/commonFunction"
import AggregationDropdown from "./AggregationDropdown";

const AnalyticsRightSidebarProductionTab = () => {
    const wellsAndRigs = useAppSelector((state) => state.wellsAndRigs);

    const disableNormalize = useMemo(() => {
        // If one of the selected wells has a 0 lateral length then we should disable 'Normalize'
        if (wellsAndRigs.selectedWellRigOrPermitList.map((well: WellsAndPermitsObject | ProductionDataObj | RigsDataObj) => !isInstanceOfWellsAndPermitsObject(well) ? 1 : well.lateral_length ? well.lateral_length : 0).includes(0)) {
            return true
        }
        return false
    }, [wellsAndRigs.selectedWellRigOrPermitList])

    const notAllHorizontalWellsSelected = useMemo(() => {
        // If one of the selected wells has a 0 lateral length then we should disable 'Normalize'
        let notAllHor = false
        for (let well of wellsAndRigs.selectedWellRigOrPermitList) {
            if (isInstanceOfWellsAndPermitsObject(well) && well.drill_type !== "Horizontal") {
                notAllHor = true
                break
            }
        }

        return notAllHor;
    }, [wellsAndRigs.selectedWellRigOrPermitList])

    // const idsOfSelectedWells = useMemo(() => { return combineListOfStringsIntoOne(wellsAndRigs.selectedWellRigOrPermitUIDList) }, [wellsAndRigs.selectedWellRigOrPermitUIDList])

    const dispatch = useAppDispatch();
    const typeCurve = wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? true : false;

    const [state, setState] = useState<{
        showDropDownOption: boolean, closeOilMaxMsg: boolean, closeGasMaxMsg: boolean
    }>({
        showDropDownOption: false, closeOilMaxMsg: false, closeGasMaxMsg: false
    })

    const { closeOilMaxMsg, closeGasMaxMsg } = state;


    const RenderForecastBtn = ({ typePro }: { typePro: typeof OIL | typeof GAS }) => {
        return <div className="graphBtn">
            <button
                onClick={() => {
                    if (wellsAndRigs.selectedWellRigOrPermitList.length > 1) { toast.info("Please limit selection to one well for forecasting."); }
                    else {
                        dispatch(handleShowAndHideTable(!wellsAndRigs.showTable))
                        dispatch(toggleViewAnalytics());
                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: typePro === OIL ? OIL : GAS }))
                        dispatch(handleForecast(true))
                    }
                }}
                className={`btn ${wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"] && wellsAndRigs.analyticsData.type === ANALYTICS_MONTHLY_TAB ? "active" : ""}`}
                disabled={wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"] && wellsAndRigs.analyticsData.type === ANALYTICS_MONTHLY_TAB ? false : true}>Run Forecast{wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"] ? <></> : <span>Switch to Date Time view to enable Run Forecast functionality.</span>}</button>
            <button onClick={() => {
                if (wellsAndRigs.selectedWellRigOrPermitList.length > 1 && ((wellsAndRigs.analyticsData.type === ANALYTICS_CUM_TAB && wellsAndRigs.analyticsData.action === "none") || (wellsAndRigs.analyticsData.type === ANALYTICS_MONTHLY_TAB && wellsAndRigs.analyticsData.action === "none"))) { toast.info(typeCurve ? "Please limit selection to one well to run type curve or perform aggregation." : "Please limit selection to one well for forecasting."); } else {
                    dispatch(handleShowAndHideTable(!wellsAndRigs.showTable))
                    dispatch(toggleViewAnalytics());
                    dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: typePro === OIL ? OIL : GAS }))
                    dispatch(handleForecast(true))
                }
            }}
                className={`btn ${wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] && wellsAndRigs.analyticsData.type === ANALYTICS_MONTHLY_TAB ? "active" : ""}`}
                disabled={wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] && wellsAndRigs.analyticsData.type === ANALYTICS_MONTHLY_TAB ? false : true}>Run Type Curve{wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? <></> : <span>Switch to Producing Time view to enable Type Curve functionality.</span>}</button>
        </div>
    }

    return (
        <>
            <div className="row-space-between">
                <div className="analyticsTab">
                    <ul className="nav" id="myTabaoi" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="productionAnalytics-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#productionAnalytics"
                                type="button"
                                role="tab"
                                onClick={() => {
                                    if (wellsAndRigs.analyticsTabIndex !== 0) {
                                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                                        dispatch(handleAnalyticsTabIndex(0))
                                    }
                                }}
                                aria-controls="productionAnalytics"
                                aria-selected={true}
                            >
                                <span className="text">Production</span>{" "}
                            </button>
                        </li>
                    </ul>

                </div>
                <DownloadProductionLink iconSize="large" />
            </div >
            <div className="tab-content tabSection" id="myTabContentaoi">
                <div
                    className="tab-pane fade show active"
                    id="productionAnalytics"
                    role="tabpanel"
                    aria-labelledby="productionAnalytics-tab"
                >
                    <Scrollbars
                        className="cardsection-scroll"
                        renderThumbVertical={(props) => (
                            <div {...props} className="thumb-vertical" />
                        )}
                        renderTrackVertical={(props) => (
                            <div {...props} className="track-vertical" />
                        )}
                    >
                        <div className="analyticsHeader">
                            <div className="navli monthly">
                                <Link className={wellsAndRigs.analyticsData.type === ANALYTICS_MONTHLY_TAB ? "active" : ""} to={""}
                                    onClick={() => {
                                        if (wellsAndRigs.analyticsData.type !== ANALYTICS_MONTHLY_TAB) {
                                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                                            dispatch(handleAnalyticsProdFilters({
                                                type: ANALYTICS_MONTHLY_TAB
                                            }));
                                        }
                                    }}
                                >Monthly</Link>
                                <Link className={wellsAndRigs.analyticsData.type === ANALYTICS_CUM_TAB ? "active" : ""} to={""} onClick={() => {
                                    if (wellsAndRigs.analyticsData.type !== ANALYTICS_CUM_TAB) {
                                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                                        dispatch(handleAnalyticsProdFilters({ ...(wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED && wellsAndRigs.analyticsData.action === "none" && { action: 'average' }), type: ANALYTICS_CUM_TAB }));
                                    }
                                }}>Cum</Link>
                            </div>
                            <div className="navli timely">
                                {Object.keys(LINE_CHART_XAXIS_FILTERS).map((item, index) => {
                                    let tempValue = LINE_CHART_XAXIS_FILTERS[item as keyof typeof LINE_CHART_XAXIS_FILTERS];

                                    return <Link className={tempValue === wellsAndRigs.analyticsData.xAxisFilter ? "active" : ""} to={""} key={index}
                                        onClick={() => {
                                            if (wellsAndRigs.analyticsData.xAxisFilter !== tempValue) {
                                                let producingTime = wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? 'producing_time' : 'normal_time';
                                                let oilMaybeCum = 'oil'
                                                let gasMaybeCum = 'gas'
                                                let actionMaybeNormalized: string = wellsAndRigs.analyticsData.action || 'none'
                                                if (wellsAndRigs.analyticsData.type === ANALYTICS_CUM_TAB) {
                                                    oilMaybeCum = `${oilMaybeCum}-cum`
                                                    gasMaybeCum = `${gasMaybeCum}-cum`
                                                }
                                                if (wellsAndRigs.analyticsData.normalized) {
                                                    actionMaybeNormalized = `${wellsAndRigs.analyticsData.action}-normalized`
                                                }
                                                dispatch(handleAnalyticsProdFilters({ xAxisFilterVal: tempValue, type: wellsAndRigs.analyticsData.type }));
                                            }
                                        }}
                                    >{item}</Link>
                                })}
                            </div>
                        </div>
                        <div className="card-box">
                            <div className="column-flex-short">
                                <div className="card-box-header">
                                    <div className="labelheading">Liquids Production</div>
                                    <div className="action-btn">
                                        <Link to="" onClick={() => {
                                            dispatch(handleShowAndHideTable(!wellsAndRigs.showTable))
                                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: OIL }))
                                        }}>
                                            <img
                                                src="images/full-screen.svg"
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                </div>
                                {wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED && !sessionStorage.getItem("oilMaxMsg") && !closeOilMaxMsg && !closeGasMaxMsg ? <div className="excedsMessage">
                                    <div className="text-block">
                                        <img src="images/exclamation.svg" alt="" />
                                        Your selection exceeded 50 wells, so we've aggregated them for simplicity. You can change the aggregation method from the dropdown.
                                    </div>
                                    <span className="close" onClick={() => {
                                        setState((prev) => ({ ...prev, closeOilMaxMsg: true }))
                                    }}><img src="images/close.svg" alt="" /></span>

                                    <div className="custom-checkbox">
                                        <input name="rememberMe" className="form-control checkmark" type="checkbox" id="exceds" onChange={e => {
                                            const { checked } = e.target;
                                            if (checked) {
                                                sessionStorage.setItem("oilMaxMsg", "true");
                                            } else {
                                                sessionStorage.removeItem("oilMaxMsg");
                                            }
                                        }} />
                                        <label htmlFor="exceds" className="custom-label"> Don’t show this message again</label>
                                    </div>
                                </div> : <></>}
                                <div className="normalize">
                                    <AggregationDropdown />
                                    <div className={`${wellsAndRigs.analyticsData.apiListObjLength === wellsAndRigs.analyticsData.apiListObj.length ? "grey" : ""} noramlizeBlock`}>
                                        Normalize&nbsp;<i data-tooltip-id="analytics-production-tooltip"
                                            data-tooltip-html="<div><span>Normalize horizontal well production</span><br/><span>for lateral length.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light"><img src="images/exclamation.svg" alt="" /></i>
                                        <label className={`switch`} data-tooltip-id="disable-normalize-tooltip"
                                            data-tooltip-html={notAllHorizontalWellsSelected ? "<div><span>Selection should only</span><br/><span>include horizontal wells.</span></div>" : "<div><span>Selection contains well(s) with</span><br/><span>with missing lateral length(s).</span></div>"}
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <input
                                                disabled={wellsAndRigs.analyticsData.apiListObjLength === wellsAndRigs.analyticsData.apiListObj.length || disableNormalize || notAllHorizontalWellsSelected ? true : false}
                                                type="checkbox"
                                                checked={wellsAndRigs.analyticsData.normalized ? true : false}
                                                onChange={(e) => {
                                                    const { checked } = e.target
                                                    dispatch(handleNormalize(checked))
                                                    /** we first try and see if there is already aggregation data for the same
                                                     * list of wells, cum/not-cum, normalized/not-normalized, and action.  if so,
                                                     * then we just use that
                                                     */
                                                    let producingTime: string = wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? 'producing_time' : 'normal_time';

                                                    let oilMaybeCum: string = 'oil'
                                                    let gasMaybeCum: string = 'gas'
                                                    let actionMaybeNormalized: string = wellsAndRigs.analyticsData.action || 'none'
                                                    if (wellsAndRigs.analyticsData.type === ANALYTICS_CUM_TAB) {
                                                        oilMaybeCum = `${oilMaybeCum}-cum`
                                                        gasMaybeCum = `${gasMaybeCum}-cum`
                                                    }
                                                    if (checked) {
                                                        actionMaybeNormalized = `${wellsAndRigs.analyticsData.action}-normalized`
                                                    }
                                                    setState((prev) => ({ ...prev, showDropDownOption: false }));

                                                }
                                                }
                                            />
                                            <span className={`slider round ${disableNormalize || notAllHorizontalWellsSelected ? 'disabled' : ''}`}></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="garph">
                                {/* <img src="images/graph-1.svg" alt="" /> */}

                                {wellsAndRigs.analyticsData.apiList.length === 0 ? <></> : (wellsAndRigs.analyticsData.graphDataLoading) ? <div className="norecord">Loading...</div> : <LineChart usedFor={OIL} yLabel={`${wellsAndRigs.analyticsData.normalized ? "bbl/1000ft" : "bbl"}`} id={OIL} useDateXAxis={wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"]} />}
                            </div>
                            <RenderForecastBtn typePro={OIL} />
                        </div>
                        <div className="card-box">
                            <div className="card-box-header">
                                <div className="labelheading">Gas Production</div>
                                <div className="action-btn">
                                    <Link to="" onClick={() => {
                                        dispatch(handleShowAndHideTable(!wellsAndRigs.showTable))
                                        dispatch(toggleViewAnalytics());
                                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: GAS }))
                                    }}>
                                        <img
                                            src="images/full-screen.svg"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                            </div>
                            {wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED && !sessionStorage.getItem("gasMaxMsg") && !closeGasMaxMsg && !closeOilMaxMsg ? <div className="excedsMessage">
                                <div className="text-block">
                                    <img src="images/exclamation.svg" alt="" />
                                    Your selection exceeded 50 wells, so we've aggregated them for simplicity. You can change the aggregation method from the dropdown.
                                </div>
                                <span className="close" onClick={() => {
                                    setState((prev) => ({ ...prev, closeGasMaxMsg: true }))
                                }}><img src="images/close.svg" alt="" /></span>

                                <div className="custom-checkbox">
                                    <input name="rememberMeGas" className="form-control checkmark" type="checkbox" id="gasMaxMsg" onChange={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                            sessionStorage.setItem("gasMaxMsg", "true");
                                        } else {

                                            sessionStorage.removeItem("gasMaxMsg");
                                        }
                                    }} />
                                    <label htmlFor="gasMaxMsg" className="custom-label"> Don’t show this message again</label>
                                </div>
                            </div> : <></>}
                            <div className="garph">
                                {wellsAndRigs.analyticsData.apiList.length === 0 ? <></> : (wellsAndRigs.analyticsData.graphDataLoading) ? <div className="norecord">Loading...</div> : <LineChart usedFor={GAS} yLabel={`${wellsAndRigs.analyticsData.normalized ? "mcf/1000ft" : "mcf"}`} id={GAS} useDateXAxis={wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"]} />}
                            </div>
                            <RenderForecastBtn typePro={GAS} />
                        </div>
                    </Scrollbars>
                </div>
                <div
                    className="tab-pane fade"
                    id="welltype"
                    role="tabpanel"
                    aria-labelledby="welltype-tab"
                >
                    <div className="tabBlockContent">Well</div>
                </div>
            </div>
            <Tooltip id="analytics-production-tooltip" />
            {disableNormalize || notAllHorizontalWellsSelected ? <Tooltip id="disable-normalize-tooltip" /> : ''}

        </>
    );
};

export default AnalyticsRightSidebarProductionTab;
