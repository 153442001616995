import * as React from "react"
import { SVGProps } from "react"
const AlertSettings = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={17}
        fill="none"
        {...props}
    >
        <path
            //   fill="#FFF9F9"
            fill={props.fill || "#696B6C"}
            d="M.5 13.438c0-.432.332-.797.797-.797h1.959a2.693 2.693 0 0 1 2.557-1.86c1.162 0 2.19.797 2.523 1.86h8.367c.432 0 .797.365.797.796a.787.787 0 0 1-.797.797H8.336a2.64 2.64 0 0 1-2.524 1.86c-1.195 0-2.224-.764-2.556-1.86h-1.96a.767.767 0 0 1-.796-.796Zm6.375 0c0-.565-.498-1.063-1.063-1.063a1.07 1.07 0 0 0-1.062 1.063c0 .597.465 1.062 1.063 1.062a1.07 1.07 0 0 0 1.062-1.063Zm5.313-7.97c1.162 0 2.19.798 2.523 1.86h1.992c.432 0 .797.365.797.797a.787.787 0 0 1-.797.797h-1.992a2.64 2.64 0 0 1-2.524 1.86c-1.195 0-2.224-.764-2.556-1.86H1.297A.767.767 0 0 1 .5 8.125c0-.432.332-.797.797-.797H9.63a2.693 2.693 0 0 1 2.556-1.86Zm1.062 2.657c0-.564-.498-1.063-1.063-1.063a1.07 1.07 0 0 0-1.062 1.063c0 .598.465 1.063 1.063 1.063a1.07 1.07 0 0 0 1.062-1.063Zm3.453-6.11c.432 0 .797.366.797.797a.787.787 0 0 1-.797.797H9.398a2.64 2.64 0 0 1-2.523 1.86c-1.195 0-2.225-.764-2.557-1.86H1.297A.767.767 0 0 1 .5 2.813c0-.432.332-.797.797-.797h3.021A2.693 2.693 0 0 1 6.875.156c1.162 0 2.191.797 2.523 1.86h7.305Zm-10.89.797c0 .598.464 1.063 1.062 1.063a1.07 1.07 0 0 0 1.063-1.063c0-.564-.499-1.062-1.063-1.062a1.07 1.07 0 0 0-1.063 1.063Z"
        />
    </svg>
)
export default AlertSettings
