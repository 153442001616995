import React, { useEffect, useMemo, useRef, useState } from "react";
import TabHeadingSec from "./TabHeadingSec";
// import Filter from "./Filter";
import TabCont from "./TabCont";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useWindowDimensions from "../../hooks/useWindowDimension";
import _ from "lodash";
import {
    clearProductionData,
    clearRigsData,
    clearWellsData,
    handleUIDList,
    showHideColProperties
} from "../../store/actions/wells-rigs-action";
import {
    handleSavedFilterColumnProperties,
} from '../../store/actions/cart-basin-to-county-actions';

function TabSection() {

    const siteLoader = useAppSelector(state => state.modal.siteLoader);
    const wellsList = useAppSelector(state => state.wellsAndRigs.wellsData.data);
    const rigsList = useAppSelector(state => state.wellsAndRigs.rigsData.data);
    const permitsList = useAppSelector(state => state.wellsAndRigs.permitsData.data);
    const productionList = useAppSelector(state => state.wellsAndRigs.productionData.data);
    const wellsAndRigs = useAppSelector(state => state.wellsAndRigs);
    const openSavedFilterColumnProperties = useAppSelector(state => state.cartBasinToCounty.savedFilterColumnProperties.openSavedFilterColumnProperties);


    const dispatch = useAppDispatch();

    const { height } = useWindowDimensions()

    const [state, setState] = useState({
        noOfRows: 5,
        // showWellsMaxLimitModal: false
    })
    const {
        noOfRows,
        // showWellsMaxLimitModal
    } = state;
    const wellsMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [wellsList]);
    const permitsMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [permitsList]);
    const rigsMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [rigsList]);
    const productionMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [productionList]);

    useEffect(() => {
        if (wellsAndRigs.fullScreen) { setState(prev => ({ ...prev, noOfRows: Math.trunc((height - 100) / 32) })) } else {
            setState(prev => ({ ...prev, noOfRows: 5 }))
        }
        // eslint-disable-next-line
    }, [
        wellsAndRigs.fullScreen
    ])

    const wellsRigsPermitProdData = useMemo(() => {
        if (wellsAndRigs.tabIndex === 0) {
            return wellsList
        }
        if (wellsAndRigs.tabIndex === 1) {
            return rigsList
        }
        if (wellsAndRigs.tabIndex === 2) {
            return permitsList
        }
        return productionList
    }, [wellsAndRigs.tabIndex, wellsList, rigsList, permitsList, productionList])
    // this flag is used to unchecked the checkbox when data is selected from the existing table list
    const doNotRemoveRow = useRef(false);
    //this ref is used to store the uid already present in the table so it rows can get deleted when user click on new wells without closing the previous open pop-up.
    const alreadyPresentUID = useRef<string[]>([]);

    useEffect(() => {
        if (siteLoader) {
            return
        }

        if (!siteLoader && wellsAndRigs.selectedWellRigOrPermitUIDList.length === 0) {
            let temp = doNotRemoveRow.current ? ([...(wellsList || [])]).map((item) => JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList).includes(`${item.id}`) ? { ...item, checked: false } : item) : ([...(wellsList || [])]).map(_obj => ({
                ..._obj,
                // checked: false 
                ...(_obj.checked && alreadyPresentUID.current.join().includes(`${_obj.uid}`) && { checked: false })
            })).filter((item) => !JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList).includes(`${item.id}`))
            wellsAndRigs.selectedWellRigOrPermitUIDList.length && dispatch(handleUIDList({ selectedWellRigOrPermitUIDList: [], reset: true, data: temp, doNotRemoveRow: doNotRemoveRow.current }))
            doNotRemoveRow.current = false;
            alreadyPresentUID.current.length && (alreadyPresentUID.current = []);
            return
        }
        // Note:- Commenting out the 1000 well selection condition as per the ticket ID ENE-745.
        // if (wellsAndRigs.selectedWellRigOrPermitUIDList.length > 1000) {
        //     !showWellsMaxLimitModal && !sessionStorage.getItem("maxWellToast") && setState((prev) => ({ ...prev, showWellsMaxLimitModal: true }));
        //     sessionStorage.setItem("maxWellToast", "true")
        //     return
        // }
        if (wellsAndRigs.selectedWellRigOrPermitUIDList.length && wellsAndRigs.selectedWellRigOrPermitUIDList.length <= 1000) {
            //The following check is implemented to reset the tab index to 0, ensuring that the "Wells" tab gets highlighted when wells are selected through the map. Currently, the "Rigs" table does not have a unique identifier (UID)

            // let temp = wellsAndRigs.selectedWellRigOrPermitUIDList.slice(0, 1000).filter((_id) => (!JSON.stringify(data).includes(_id) && `${_id}`))
            let selectedWellsNotInWellsList = wellsAndRigs.selectedWellRigOrPermitUIDList.filter((_id) => (!JSON.stringify(wellsRigsPermitProdData).includes(_id) && `${_id}`))

            // let temp_data = (data || []).map((_item) => JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList).includes(`${_item.uid}`) ? { ..._item, checked: true } :_item );
            let temp_data = (wellsList || []).map((_item) => {
                if (JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList).includes(`${_item.uid}`)) {
                    alreadyPresentUID.current = [...alreadyPresentUID.current, `${_item.uid}`]
                    return { ..._item, checked: true }
                } else {
                    return { ..._item, ...(wellsAndRigs.selectedWellRigOrPermitUIDList.length && _item.checked && alreadyPresentUID.current.join().includes(`${_item.uid}`) && { checked: false }) }
                }
            });
            //the condition statement is added to remove the rows when user select new well without closing the pop-up of previous open pop-up
            // Jacob edited this conditional 7/24, to also make sure that the temp data does not filter out the selected
            // data.  What was happening was a user would click on a well on the map, that well data would get added to the wellsData
            // and it's API would become the selectedWellId, but then that well data was getting filtered out and the well card 
            // didn't have data to show.  I think this is because Jason removed the popover on the map.

            if (wellsAndRigs.selectedWellRigOrPermitUIDList.length) {
                temp_data = temp_data.filter((item) => !JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList).includes(`${item.uid}`) || item.id === wellsAndRigs.selectedRowId);
                alreadyPresentUID.current.length && (alreadyPresentUID.current = []);
            }

            if (selectedWellsNotInWellsList.length) {
                dispatch(clearProductionData());
                dispatch(clearWellsData());
                dispatch(clearRigsData());
            } else {
                doNotRemoveRow.current = true;
            }
        }
        // eslint-disable-next-line
    }, [JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList), siteLoader])

    const closeColumnPopover = () => {
        dispatch(showHideColProperties(false));
        openSavedFilterColumnProperties && dispatch(handleSavedFilterColumnProperties({ openSavedFilter: false }))
    }
    return (
        <>
            <div className={(!wellsAndRigs.downloadCol && wellsAndRigs.showTableLoader) ? "search-con-loader-outlay" : 'd-none'}>
                {/* <div className="search-con-loader-outlay"> */}
                <div className="search-header">
                    <div className="searchTop">
                        <div className="searchByTab">
                            <ul className="nav">
                                <li className="nav-item">
                                    <button className="nav-link" type="button"> <span></span></button>
                                </li>
                                <li className="nav-item bt1"><button className="nav-link" type="button"><span></span></button>
                                </li>
                            </ul>
                        </div>
                        <div className="showhidebtn "></div>
                    </div>
                    <div className="searchbtm">
                        <div className="left">
                            <div className="searchInput"><span></span></div>
                            <div className="searchfilter"><span></span></div>
                        </div>
                        <div className="right">
                            <div className="greenbtn"></div>
                        </div>
                    </div>
                </div>
                <div className="tablelayout">

                    <table>
                            <thead>

                        {
                            _.range(1, noOfRows).map((item, key) => {
                                return <tr key={key}>
                                    <th className="chk"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                </tr>
                            })
                        }
                        </thead>
                    </table>
                </div>
            </div >
            <div className={(wellsAndRigs.downloadCol || !wellsAndRigs.showTableLoader) ? "search-container" : "d-none"}>
                <TabHeadingSec />
                <div className={`tab-content searchCon ${wellsAndRigs.openForeCast ? "isHide" : ""} ${wellsAndRigs.showTable ? "" : "isHide"}`} id="myTabContent">
                    {/* <Filter hideTableFilters={true} hideAdvFilterBtn={false} /> */}
                    <div
                        className="tab-pane fade show active"
                        id="wells"
                        role="tabpanel"
                        aria-labelledby="wells-tab"
                        onClick={() => {
                            closeColumnPopover()
                        }}
                    >
                        {wellsMemo}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="rigs"
                        role="tabpanel"
                        aria-labelledby="rigs-tab"
                        onClick={() => {
                            closeColumnPopover()
                        }}
                    >
                        {rigsMemo}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="permits"
                        role="tabpanel"
                        aria-labelledby="permits-tab"
                        onClick={() => {
                            closeColumnPopover()
                        }}
                    >
                        {permitsMemo}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="production"
                        role="tabpanel"
                        aria-labelledby="production-tab"
                        onClick={() => {
                            closeColumnPopover()
                        }}
                    >
                        {productionMemo}
                    </div>
                </div>
            </div >
            {/* <DeleteConfirmationModal
                show={showWellsMaxLimitModal}
                handleClose={() => {
                    setState((prev) => ({ ...prev, showWellsMaxLimitModal: false }));
                    sessionStorage.removeItem("sessionStorage")
                }}
                confirmBtnClick={() => {
                    setState((prev) => ({ ...prev, showWellsMaxLimitModal: false }))
                    sessionStorage.removeItem("sessionStorage")

                }}
                closeBtn={true}
                content={<p>Please limit your search to 1,000 wells or less.</p>}
            /> */}
        </>
    );
}

export default TabSection;
