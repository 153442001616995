import React, { useState, useMemo, useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { Link } from "react-router-dom";
import { getSelectedWell, addHours, formatDateMMDDYYYY, addCommasToNumber } from "./AoiUtils"
import xlsx, { IContent } from 'json-as-xlsx'
import moment from "moment";
import {
    handleProductionTabIndex,
    handleSetProductionDataLoading
} from "../store/actions/wells-rigs-action";
import GlobalTable from "../common/GlobalTable";
import { GlobalTableProps } from "../models/page-props";
import useProductionDataCol from "./ProductionnTableColumns";
import {
    ProductionObjectNestedType,
    tableColObje,
    SingleWellProductionObj,
    SingleWellLeaseProductionObj
} from '../models/redux-models'
import { handleFreeTrialDownAlertMsgModal } from "../store/actions/modal-actions";
import Renderers from "../map/config/carto/Renderers";
import { toast } from "react-toastify";

const AnalyticsRightSidebarWellAttributesTab = () => {
    const siteLoader = useAppSelector((state) => state.modal.siteLoader);
    const company_configs = useAppSelector((state) => state.auth.user.company_configs);
    const analyticsData = useAppSelector((state) => state.wellsAndRigs.analyticsData);
    const wellsData = useAppSelector((state) => state.wellsAndRigs.wellsData);
    const wellsAndRigs = useAppSelector((state) => state.wellsAndRigs);

    const dispatch = useAppDispatch();
    type DownloadCSVDataProduction = { production_month: string, oil_prod: string, gas_prod: string }
    type DownloadCSVDataLease = { production_month: string, lease_oil_prod: string, lease_gas_prod: string }
    const [totalLiqProduction, setTotalLiqProduction] = useState<number | string>('')
    const [totalGasProduction, setTotalGasProduction] = useState<number | string>('')
    const [leaseLiqProduction, setLeaseLiqProduction] = useState<number | string>('')
    const [leaseGasProduction, setLeaseGasProduction] = useState<number | string>('')
    const [selectedLeaseID, setSelectedLeaseID] = useState<string>('')
    const [showDropDownOption, setShowDropDownOption] = useState<boolean>(false)
    const [productionDataLoadingComponent, setProductionDataLoadingComponent] = useState<boolean>(true)
    const [leaseProductionDataLoadingComponent, setLeaseProductionDataLoadingComponent] = useState<boolean>(true)
    const productionColumnData: tableColObje[] = [
        { label: 'Production Month', status: true, header: 'Production&nbsp;Month' },
        { label: 'Liquids Prod (BBL)', status: true, header: 'Liquids Prod&nbsp;(BBL)' },
        { label: 'Gas Prod (MCF)', status: true, header: 'Gas Prod&nbsp;(MCF)' }
    ]
    const cumulativeProductionColumnData: tableColObje[] = [
        { label: 'total', status: true, header: totalLiqProduction || totalGasProduction ? 'Total&NBSP;' : '&NBSP;' },
        { label: 'liqProd', status: true, header: `${totalLiqProduction.toString()}&NBSP;` },
        { label: 'gasProd', status: true, header: `${totalGasProduction.toString()}&NBSP;` }
    ]
    const leaseProductionColumnData: tableColObje[] = [
        { label: 'Production Month', status: true, header: 'Production&nbsp;Month' },
        { label: 'Liquids Prod (BBL)', status: true, header: 'Liquids Prod&nbsp;(BBL)' },
        { label: 'Gas Prod (MCF)', status: true, header: 'Gas Prod&nbsp;(MCF)' },
    ]
    const cumulativeLeaseProductionColumnData: tableColObje[] = useMemo(() => {
        return [
            { label: 'total', status: true, header: leaseLiqProduction || leaseGasProduction ? 'TOTAL&NBSP; ' : "&NBSP;" },
            { label: 'liqProd', status: true, header: `${leaseLiqProduction.toString()}&NBSP;` },
            { label: 'gasProd', status: true, header: `${leaseGasProduction.toString()}&NBSP;` },
        ]
    }, [leaseLiqProduction, leaseGasProduction])

    const [canShowLeaseTab, setCanShowLeaseTab] = useState<boolean>(false)
    const [canShowProdTab, setCanShowProdTab] = useState<boolean>(true)

    /* 
    I am choosing to have two loading variables for the production and lease data.  
    There is already the productionDataLoading and leaseProductionDataLoading in redux.
    These are set to true when there is just 1 well selected, and will stay true while 
    the api call call is made.
    These state variables are set to false, after the api call is complete and the production
    data is updated in redux.  I want to maintain those defintitions throughout the app 
    (redux loading variables are false after data is retrieved from the backend.)
    However, just making the api call successfully doesn't mean the card can shift from loading
    from rendering the table, because there are multiple hooks that need to be triggered for 
    data processing.  Therefore, I'm creating separate loading variables at this component level
    (productionDataLoadingComponent and leaseProductionDataLoadingComponent).  They are true
    if the redux ones are true, otherwise, are only set after the last hook has processed the data
    (or there isn't data to process)
    */
    useEffect(() => {
        // if we start loading the api call and have nuked the previous data, 
        // then we should set loading to true
        if (analyticsData.graphDataLoading || !analyticsData.singleWellProd) {
            setProductionDataLoadingComponent(true)
        }
        if (analyticsData.graphDataLoading || !analyticsData.singleWellLeaseProd) {
            setLeaseProductionDataLoadingComponent(true)
        }
        if (analyticsData.graphDataLoading) {
            setCanShowLeaseTab(true)
            setCanShowProdTab(true)
        }

    }, [analyticsData.graphDataLoading, analyticsData.singleWellLeaseProd])

    useEffect(() => {
        if (analyticsData.leaseIDs && analyticsData.leaseIDs.length > 0) {
            setSelectedLeaseID(analyticsData.leaseIDs[0])
        }
    }, [analyticsData.leaseIDs])

    const selectedWell = useMemo(() => {
        if (wellsAndRigs.selectedWellRigOrPermitUIDList.length === 1) {
            return getSelectedWell(wellsData.data, wellsAndRigs.selectedWellRigOrPermitUIDList[0])
        }
        return null
    }, [wellsData.data, wellsAndRigs.selectedWellRigOrPermitUIDList])


    const combinedOilGasProdDataForTable = useMemo(() => {
        {/* The selected oil and gas data is a list of objects with a production_date key
        with a string date, and a production_qty with a number value.  
        
        selectedOilData = [{production_date: '1992-01-02', production_qty: 342}]

        We need a single object to combine oil and gas data with the key names to relate to the
        column headers: 'Production Month' 'Liquids Prod (BBL)' 'Gas Prod (MCF)'
        
        combinedData = [{'Production Month': 'Jan 1992', 'Liquids Prod (BBL)': 342, 'Gas Prod (MCF)': 0}]

        One tricky part is if there is data for one month in the oil data, but not data for gas.  We
        need the data in chronological order, so I am also adding a key / value of a javascript Date
        in order to sort by date at the end, so it stays in order. 

        The approach to creating this list, is to first create an object with a key of the production month.
        This will allow me to only loop through the selectedOilData and selectedGasData once.  Then, I can 
        make the final list from the keys of the temp object

        tempObject = {'Jan 1992': {'Production Month': 'Jan 1992', 'Liquids Prod (BBL)': 342, 'Gas Prod (MCF)': 0}}

        */}
        if (analyticsData.graphDataLoading) {
            return []
        } else if (!analyticsData.singleWellProd || analyticsData.singleWellProd.length === 0) {
            setProductionDataLoadingComponent(false)
            return []
        }
        setProductionDataLoadingComponent(true)
        const threeLetterMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let totalLiq: number = 0
        let totalGas: number = 0
        if (!analyticsData.hasWellProd) {
            return []
        }
        const combinedDataList: ProductionObjectNestedType[] = []
        analyticsData.singleWellProd.forEach((singleWell: SingleWellProductionObj) => {
            const jsDate = addHours(new Date(singleWell.production_date));
            const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
            totalLiq += singleWell.production_qty_liq;
            totalGas += singleWell.production_qty_gas
            combinedDataList.push({
                'Production Month': monthYear,
                'Liquids Prod (BBL)': addCommasToNumber(singleWell.production_qty_liq),
                'Gas Prod (MCF)': addCommasToNumber(singleWell.production_qty_gas),
                jsDate
            })
        })

        setTotalLiqProduction(addCommasToNumber(totalLiq))
        setTotalGasProduction(addCommasToNumber(totalGas))
        if (combinedDataList.length > 0) {
            dispatch(handleSetProductionDataLoading(false))
        }

        // if production data loading is false, meaning the api call finished, and there is selected oil or gas data, meaning that 
        // use effect hook already ran and populated this data, but there is still not combined data, then we should set loading to false
        setProductionDataLoadingComponent(false)

        return combinedDataList ?? []

    }, [analyticsData.singleWellProd, analyticsData.graphDataLoading])

    const combinedOilGasLeaseDataForTable = useMemo(() => {

        if (analyticsData.graphDataLoading) {
            return []
        } else if ((!analyticsData.singleWellLeaseProd || analyticsData.singleWellLeaseProd.length === 0) || !analyticsData.leaseIDs) {
            setLeaseProductionDataLoadingComponent(false)
            return []
        }
        setLeaseProductionDataLoadingComponent(true)

        const threeLetterMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let totalLiq: number = 0
        let totalGas: number = 0
        if (!analyticsData.hasLeaseProd) {
            return []
        }
        const combinedDataList: ProductionObjectNestedType[] = []
        analyticsData.singleWellLeaseProd.filter((singleLease: SingleWellLeaseProductionObj) => singleLease.lease_id === selectedLeaseID).forEach((singleLease: SingleWellLeaseProductionObj) => {
            const jsDate = addHours(new Date(singleLease.production_date));
            const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
            totalLiq += singleLease.production_qty_liq;
            totalGas += singleLease.production_qty_gas
            combinedDataList.push({
                'Production Month': monthYear,
                'Liquids Prod (BBL)': addCommasToNumber(singleLease.production_qty_liq),
                'Gas Prod (MCF)': addCommasToNumber(singleLease.production_qty_gas),
                jsDate
            })
        })
        setLeaseLiqProduction(addCommasToNumber(totalLiq))
        setLeaseGasProduction(addCommasToNumber(totalGas))
        setLeaseProductionDataLoadingComponent(false)

        return combinedDataList

    }, [analyticsData.singleWellLeaseProd, selectedLeaseID, analyticsData.graphDataLoading, analyticsData.leaseIDs])

    const wellDetails = [
        {
            name: 'OPERATOR',
            value: selectedWell?.operator_name
        },
        {
            name: 'WELL NAME',
            value: selectedWell?.well_name
        },
        {
            name: 'API',
            value: selectedWell?.well_api
        },
        {
            name: 'ORIENTATION',
            value: selectedWell?.drill_type
        },
        {
            name: 'RESERVOIR',
            value: selectedWell?.reservoir ?? ''
        },
        {
            name: 'COUNTY',
            value: selectedWell?.county
        },
        {
            name: 'STATE',
            value: selectedWell?.state_name
        },
        {
            name: 'WELL STATUS',
            value: selectedWell?.well_status
        },
        {
            name: 'PERMIT DATE',
            value: formatDateMMDDYYYY(selectedWell?.permit_date ?? '')
        },
        {
            name: 'SPUD DATE',
            value: formatDateMMDDYYYY(selectedWell?.spud_date ?? '')
        },
        {
            name: 'COMPLETION DATE',
            value: formatDateMMDDYYYY(selectedWell?.completion_date ?? '')
        },
    ]

    useEffect(() => {
        // if there the well is in a state with a lease tab, and the lease tab is not currently selected,
        // and we finished loading the lease and production data, and there is not production data but there
        // is lease data, then switch to the lease data. Also, we will just not even show the wells tab
        if (!leaseProductionDataLoadingComponent && !productionDataLoadingComponent && analyticsData.hasLeaseProd && (!combinedOilGasProdDataForTable || combinedOilGasProdDataForTable.length === 0) && (combinedOilGasLeaseDataForTable && combinedOilGasLeaseDataForTable.length > 0)) {
            setCanShowLeaseTab(true)
            setCanShowProdTab(false)
            dispatch(handleProductionTabIndex(1))
        }
    }, [analyticsData.hasLeaseProd, wellsAndRigs.productionTabIndex, combinedOilGasProdDataForTable, combinedOilGasLeaseDataForTable, leaseProductionDataLoadingComponent, productionDataLoadingComponent])

    useEffect(() => {
        // if there the well is in a state with a lease tab, and the lease tab is not currently selected,
        // and we finished loading the lease and production data, and there is not production data but there
        // is lease data, then switch to the lease data. Also, we will just not even show the wells tab
        if (!leaseProductionDataLoadingComponent && !productionDataLoadingComponent && analyticsData.hasWellProd && (combinedOilGasProdDataForTable && combinedOilGasProdDataForTable.length > 0) && (!analyticsData.singleWellLeaseProd || analyticsData.singleWellLeaseProd.length === 0)) {
            setCanShowLeaseTab(false)
            setCanShowProdTab(true)
            dispatch(handleProductionTabIndex(0))
        }
    }, [analyticsData.hasLeaseProd, wellsAndRigs.productionTabIndex, combinedOilGasProdDataForTable, combinedOilGasLeaseDataForTable, leaseProductionDataLoadingComponent, productionDataLoadingComponent])

    const productionTableColumnData = useProductionDataCol(leaseProductionColumnData) as GlobalTableProps["cols"]
    const leaseTableColumnData = useProductionDataCol(productionColumnData) as GlobalTableProps["cols"]

    const tableColumnData = () => {
        if (analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1) {
            return productionTableColumnData
        }
        return leaseTableColumnData
    }

    const tableData = useMemo(() => {
        if (analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1) {
            return combinedOilGasLeaseDataForTable
        }
        return combinedOilGasProdDataForTable
    }, [analyticsData.hasLeaseProd, wellsAndRigs.productionTabIndex, combinedOilGasLeaseDataForTable, combinedOilGasProdDataForTable])

    const isLoading = useMemo(() => {
        if (productionDataLoadingComponent || leaseProductionDataLoadingComponent || wellsAndRigs.showTableLoader || wellsData.wellsDataLoading || siteLoader) {
            return true
        }
        return false
    }, [productionDataLoadingComponent, leaseProductionDataLoadingComponent, wellsAndRigs.showTableLoader, wellsData.wellsDataLoading, siteLoader])
    const isLoadingTable = useMemo(() => {
        if ((productionDataLoadingComponent && wellsAndRigs.productionTabIndex === 0) || (leaseProductionDataLoadingComponent && wellsAndRigs.productionTabIndex === 1) || wellsAndRigs.showTableLoader || wellsData.wellsDataLoading || siteLoader) {
            return true
        }
        return false
    }, [productionDataLoadingComponent, leaseProductionDataLoadingComponent, wellsAndRigs.productionTabIndex, wellsAndRigs.showTableLoader, wellsData.wellsDataLoading, siteLoader])

    const isLoadingExceptLease = useMemo(() => {
        if (productionDataLoadingComponent || wellsAndRigs.showTableLoader || wellsData.wellsDataLoading || siteLoader) {
            setTotalGasProduction('')
            setTotalLiqProduction('')
            return true
        }
        return false
    }, [productionDataLoadingComponent, wellsAndRigs.showTableLoader, wellsData.wellsDataLoading, siteLoader])

    const isLoadingExceptProd = useMemo(() => {
        if (leaseProductionDataLoadingComponent || wellsAndRigs.showTableLoader || wellsData.wellsDataLoading || siteLoader) {

            return true
        }
        return false
    }, [leaseProductionDataLoadingComponent, wellsAndRigs.showTableLoader, wellsData.wellsDataLoading, siteLoader])

    useEffect(() => {
        if (analyticsData.graphDataLoading || wellsAndRigs.showTableLoader || wellsData.wellsDataLoading || siteLoader) {
            setLeaseGasProduction('')
            setLeaseLiqProduction('')
        }
    }, [analyticsData.graphDataLoading, wellsAndRigs.showTableLoader, wellsData.wellsDataLoading, siteLoader])
    const emptyPlaceholder = <>
        {isLoadingExceptLease ? <div className="full-center-column"><span>Loading ...</span></div> : <div className="full-center-column">
            <img src="images/barrel-crossed-out.svg" alt="Pumpjack" className="margin-bottom-point-3" /> <span>Production Data Unavailable
            </span>{" "}
        </div>}</>
    const emptyPlaceholderLease = <>{isLoadingExceptProd ? <div className="full-center-column"><span>Loading ...</span></div> : <div className="full-center-column">
        <img src="images/barrel-crossed-out.svg" alt="Pumpjack" className="margin-bottom-point-3" /> <span>Production Data Unavailable
        </span>{" "}
    </div>}</>

    const wellStatusAndSymbol = useMemo(() => {
        if (!selectedWell) {
            return ''
        }

        let wellInfoList = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.filter((info: any) => info.value.toString() === selectedWell.parsed_status_id.toString())
        if (wellInfoList.length === 0) {
            // return Other if it's not anything else
            wellInfoList = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.filter((info: any) => info.value.toString() === "10")

        }
        return (<div className="gap-3 well-status-symbol">
            <img src={`${wellInfoList[0].symbol.url}`} alt="well-symbol" className="well-symbol-wellcard-header" />
            {wellInfoList[0].label}
        </div>)
    }, [selectedWell])

    const copyToClipboard = (api: string | undefined) => {
        if (!api) {
            return
        }
        navigator.clipboard.writeText(api)
            .then(() => {
                toast.info(`Copied API`, {
                    toastId: api,
                });
            })
            .catch(err => {
                toast.error('Failed to copy text', {
                    toastId: api,
                });
            });
    }

    const allocatedBadge = (
        <div className="row-well-allocated"><img src="images/well_icons/allocated.svg" className="search-input-icon" alt="" />Allocated</div>
    )
    const allocatedBadgeNoMargin = (
        <div className="row-well-allocated-no-margin"><img src="images/well_icons/allocated.svg" className="search-input-icon" alt="" />Allocated</div>
    )
    const leaseDropdown = (
        <div className="lease-id-dropdown">
            <div className="row-space-between" onClick={() => {
                setShowDropDownOption((prev: boolean) => !prev)

            }}>
                Lease ID:
                <span className="cursor">{selectedLeaseID}</span>
                <img src="/images/down-angle.png" className="dropdown-image" />
            </div>
            <div className={showDropDownOption ? "dropmenu" : "d-none"}>
                <ul>
                    {analyticsData.leaseIDs.map((leaseID, index) => {
                        return <li key={index}
                            className={`cursor ${selectedLeaseID === leaseID ? "selected" : ''}`}
                            onClick={(() => {
                                if (leaseID !== selectedLeaseID) {
                                    setLeaseGasProduction('')
                                    setLeaseLiqProduction('')
                                    setLeaseProductionDataLoadingComponent(true)
                                    setSelectedLeaseID(leaseID)
                                }

                                setShowDropDownOption((prev: boolean) => !prev)

                            })}>{leaseID}</li>
                    })}
                </ul>
            </div>
        </div >
    )
    return (

        <div className="column-flex-1">
            <Scrollbars
                className="card-section-scroll-production"

                renderThumbVertical={(props) => (
                    <div {...props} className="thumb-vertical" />
                )}
                renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical" />
                )}
            >
                <div className="tab-content tabSection" id="myTabContentaoi" style={{ paddingRight: '1rem' }}>
                    <div
                        className="production-analytics-box"
                        id="productionAnalytics"
                        role="tabpanel"
                        aria-labelledby="productionAnalytics-tab"
                    >
                        <div className="space-between-row">
                            <div className="wellAttrTitleHeader">
                                <div className="wellTitle">{selectedWell && selectedWell.well_name}</div>
                                <div className="apiTitle">{selectedWell && `API: (${selectedWell.well_api})`}</div>
                            </div>

                            {wellStatusAndSymbol}

                        </div>
                        <div className="card-box-production">
                            <div className="card-box-header-production-y-padding" >
                                <div className="text-medium">Well Information</div>
                                <a href={selectedWell?.state_link ?? 'void:(0)'} onClick={(e) => {
                                    !selectedWell?.state_link && e.preventDefault();
                                }} target={selectedWell?.state_link ? "_blank" : "_self"} className={selectedWell?.state_link ? "wellAttrTopButton" : "wellAttrTopButtonDisabled"} > <img src="images/link-connected.svg" alt="State Link" className="iconImage" />{" "}
                                    State Link</a>
                            </div>
                            {wellDetails.map((wellDetail, index) => (
                                <div className="wellAttrTable" key={index}>
                                    <div className="leftBox">{wellDetail.name}</div>
                                    <div className="rightBox">
                                        {wellDetail.value ? wellDetail.value.toString().toUpperCase() : ''}
                                        {wellDetail.name === "API" ? <img src="/images/copy_icon.svg" className="make-svg-white copy-icon" onClick={() => copyToClipboard(wellDetail.value)} /> : ''}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="card-box-production-top-padding">
                            <div className="card-box-header-production-x-padding">
                                {analyticsData.hasLeaseProd || isLoading ? <div className={isLoading ? "analyticsTab display-none" : !canShowLeaseTab || !canShowProdTab ? "analyticsTab no-margin-bottom" : "analyticsTab"} style={{ borderBottom: 'none' }}>
                                    <ul className="nav" id="myTabaoi" role="tablist">
                                        {canShowProdTab ? (<li className="nav-item" role="presentation">
                                            <button
                                                className={wellsAndRigs.productionTabIndex === 0 && canShowLeaseTab ? "nav-link active" : "nav-link"}
                                                id="productionAnalytics-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#productionAnalytics"
                                                type="button"
                                                disabled={!canShowLeaseTab}
                                                role="tab"
                                                onClick={() => {
                                                    if (isLoading) {
                                                        return
                                                    }
                                                    if (wellsAndRigs.productionTabIndex !== 0) {
                                                        dispatch(handleProductionTabIndex(0))
                                                    }
                                                }}
                                                aria-controls="productionAnalytics"
                                                aria-selected={wellsAndRigs.productionTabIndex === 0}
                                            >
                                                <div className="row-point-5-gap-center">
                                                    <span className="text-medium">Well Prod</span>
                                                    {analyticsData.allocated && wellsAndRigs.productionTabIndex === 0 ? allocatedBadgeNoMargin : ''}
                                                </div>

                                            </button>
                                        </li>) : ''}
                                        {canShowLeaseTab ? (<li className="nav-item" role="presentation">
                                            <button
                                                className={wellsAndRigs.productionTabIndex === 1 && canShowProdTab ? "nav-link active" : "nav-link"}
                                                id="operators-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#operators"
                                                type="button"
                                                disabled={!canShowProdTab}
                                                role="tab"
                                                aria-controls="operators"
                                                onClick={() => {

                                                    if (wellsAndRigs.productionTabIndex !== 1) {
                                                        dispatch(handleProductionTabIndex(1))
                                                    }

                                                }}
                                                aria-selected={wellsAndRigs.productionTabIndex === 1}
                                            ><div className="row-point-5-gap-center">
                                                    <span className="text-medium">Lease Prod</span></div>
                                            </button>
                                        </li>) : ''}
                                    </ul>

                                </div> : <div className="label-heading-well-info">
                                    <span className="text-medium">Well Production</span>
                                    {analyticsData.allocated ? allocatedBadge : ''}
                                </div>}
                                {analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1 && !isLoadingExceptLease ? leaseDropdown : ""}
                                <div className={isLoading ? "action-btn-production disabled" : "action-btn-production"}>

                                    <Link to="" onClick={() => {
                                        if (isLoading) {
                                            return
                                        }
                                        if (company_configs.free_trial_period_enabled && !company_configs.download_enabled) {
                                            dispatch(handleFreeTrialDownAlertMsgModal(true))
                                            return
                                        }
                                        const tempDataLease: DownloadCSVDataLease[] = [];
                                        const tempDataProduction: DownloadCSVDataProduction[] = [];
                                        if (analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1) {

                                            combinedOilGasLeaseDataForTable.forEach((combinedItem) => {
                                                let month = combinedItem.jsDate ? String(combinedItem.jsDate.getMonth() + 1).padStart(2, '0') : '0';  // getMonth() is zero-based, so add 1
                                                let day = combinedItem.jsDate ? String(combinedItem.jsDate.getDate()).padStart(2, '0') : '0';
                                                let year = combinedItem.jsDate ? combinedItem.jsDate.getFullYear() : '0';
                                                tempDataLease.push({
                                                    production_month: `${month}-${day}-${year}`,
                                                    lease_oil_prod: (combinedItem['Liquids Prod (BBL)'] || combinedItem['Liquids Prod (BBL)'] === '0' ? combinedItem['Liquids Prod (BBL)'].toString() : '').replace(/\D/g, ''),
                                                    lease_gas_prod: (combinedItem['Gas Prod (MCF)'] || combinedItem['Gas Prod (MCF)'] === '0' ? combinedItem['Gas Prod (MCF)'].toString() : '').replace(/\D/g, ''),
                                                })
                                            })
                                            // setState((prev) => ({ ...prev, dowDataLease: tempData }))
                                        } else {

                                            combinedOilGasProdDataForTable.forEach((combinedItem) => {
                                                let month = combinedItem.jsDate ? String(combinedItem.jsDate.getMonth() + 1).padStart(2, '0') : '0';  // getMonth() is zero-based, so add 1
                                                let day = combinedItem.jsDate ? String(combinedItem.jsDate.getDate()).padStart(2, '0') : '0';
                                                let year = combinedItem.jsDate ? combinedItem.jsDate.getFullYear() : '0';
                                                tempDataProduction.push({
                                                    production_month: `${month}-${day}-${year}`,
                                                    oil_prod: (combinedItem['Liquids Prod (BBL)'] || combinedItem['Liquids Prod (BBL)'] === '0' ? combinedItem['Liquids Prod (BBL)'].toString() : '').replace(/\D/g, ''),
                                                    gas_prod: (combinedItem['Gas Prod (MCF)'] || combinedItem['Gas Prod (MCF)'] === '0' ? combinedItem['Gas Prod (MCF)'].toString() : '').replace(/\D/g, '')
                                                })
                                            })
                                            // setState((prev) => ({ ...prev, dowDataProduction: tempData }))
                                        }
                                        let fileName = `${analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1 ? 'Lease' : 'Well'}_Production_Export_${getSelectedWell(wellsData.data, wellsAndRigs.selectedRowId)?.well_api}_${moment(new Date()).format(
                                            "MMM-DD-YYYY, h:mm:ss a"
                                        )}`;
                                        let excel_data = [

                                            {
                                                sheet: "Well Production",
                                                columns: analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1 ? [
                                                    { label: "Production Month", value: "production_month" },
                                                    { label: "Liquids Prod (BBL)", value: "lease_oil_prod" },
                                                    { label: "Gas Prod (MCF)", value: "lease_gas_prod" },
                                                ] : [
                                                    { label: "Production Month", value: "production_month" },
                                                    { label: "Liquids Prod (BBL)", value: "oil_prod" },
                                                    { label: "Gas Prod (MCF)", value: "gas_prod" },
                                                ],
                                                content: (analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1 ? tempDataLease as IContent[] : tempDataProduction as IContent[]),
                                            },
                                        ]

                                        xlsx(excel_data, {
                                            fileName,
                                            extraLength: 5,
                                        })
                                    }}>

                                        <img src="images/download.svg" alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="column-min-height-22">
                                <Scrollbars
                                    className="card-section-scroll-production"
                                    renderThumbVertical={(props) => (
                                        <div {...props} className="thumb-vertical" />
                                    )}
                                    renderTrackVertical={(props) => (
                                        <div {...props} className="track-vertical" />
                                    )}
                                >
                                    <GlobalTable
                                        tableClassName="production-table-body"
                                        cols={tableColumnData()}
                                        loadingMsg={isLoadingTable ? "Loading..." : undefined}
                                        data={isLoadingTable ? [] : tableData}
                                        trClassName="production-table-row"
                                        showColGroup={true}
                                        emptyPlaceholder={
                                            analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1 ?
                                                emptyPlaceholderLease : emptyPlaceholder}
                                    />
                                </Scrollbars>
                                <GlobalTable
                                    tableClassName="production-table-body zero-height"
                                    cols={useProductionDataCol(analyticsData.hasLeaseProd && wellsAndRigs.productionTabIndex === 1 ? cumulativeLeaseProductionColumnData : cumulativeProductionColumnData) as GlobalTableProps["cols"]}
                                    loadingMsg={"Loading..."}
                                    // rowId={tabIndex === 0 ? (wellsAndRigs.selectedRowId as string) : (wellsAndRigs.selectedRowId as number)}
                                    emptyPlaceholder=" "
                                    data={[]}
                                    trClassName="production-table-row zero-height"
                                    showColGroup={true}
                                    headStyle={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}

                                />
                            </div>
                        </div>
                    </div >

                    <div
                        className="tab-pane fade"
                        id="welltype"
                        role="tabpanel"
                        aria-labelledby="welltype-tab"
                    >
                        <div className="tabBlockContent">Well</div>
                    </div></div>
            </Scrollbars>
        </div >

    );
};

export default AnalyticsRightSidebarWellAttributesTab;
