import React, { useEffect, useContext } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { logout } from "../store/actions/auth-actions";
import axios from "../../utils/axios";
import { tokenIsValid } from "../../utils/helper";

function ApiPollingComponent() {
    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
    const access_token = useAppSelector(state => state.auth.user.access_token);

    const dispatch = useAppDispatch();

    useEffect(() => {
        // Only start polling if the user is logged in
        if (!isAuthenticated && !access_token) return;

        // dont run locally
        if (process.env.REACT_APP_ED_DATA_WEB_API === 'http://127.0.0.1:9000') return;

        const intervalId = setInterval(async () => {
            await tokenIsValid(access_token);
            try {
                const response = await axios.post("api-setting/logout-from-admin-call-from-front");
                const { is_need_logout, status } = response.data;

                if (is_need_logout && status === 200) {
                    clearInterval(intervalId); // Stop polling
                    dispatch(logout(access_token)); // Call the logout function
                }
            } catch (error) {
                console.error("Error calling API:", error);
            }
        }, 5000); // Poll every 5 seconds

        return () => {
            clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
    }, [isAuthenticated, access_token]); // Re-run the effect if `user` or `logout` changes

    return null; // This component doesn't render anything
}

export default ApiPollingComponent;
