import React, {
    useState
} from "react";
import { tableColObje } from "../models/redux-models";

function useProductionDataCol(tableColumnProps: tableColObje[]) {


    const [state, setState] = useState<{
        // highlight: boolean,
        dragOverItem: null | number
    }>({
        // highlight: false,
        dragOverItem: null
    });
    const {
        // highlight,
        dragOverItem } = state;
    let tableColumn = tableColumnProps


    return [
        ...tableColumn
            .filter((item) => item.status)
            .map((_item, _index) => {
                const { header, label } = _item;
                return {
                    title: header.toUpperCase(),
                    thClassName: tableColumnProps[0]?.label === 'Total&NBSP;' ? "production-table-column-header-min-width" : "",
                    // thClassName: "production-table-column-header",
                    // colClassName: highlight && draggingItem?.current === _index ? "highlight" : "",
                    thStyle: { color: "#fff", width: '33%' },
                    tdClassName: "production-table-row",
                    renderTdForAction: (
                        rowData: any, // ProductionObjectNestedType,
                        key: number,
                        index: number
                    ) => {
                        return (
                            <td
                                key={key}
                                title={label}
                                className="production-table-row-td"
                            >
                                <span>

                                    {rowData[label] !== null ? `${rowData[label]}` : ''}
                                </span>
                            </td>
                        );
                    },
                };
            }),
    ];
}

export default useProductionDataCol;
