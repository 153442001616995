import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
    CartBasinFilterState,
    CartBasinFilterFormData,
} from "../../models/page-props";
import {
    csvApiDataObj,
    BasinObjApiList,
    CountyObjApiList,
} from "../../models/submit-form";

const initialState: CartBasinFilterState = {
    shapeFileModal: false,
    fileToOpen: 0,
    openModalAFterUploadModal: false,
    isChooseColumn: false,
    isExportOther: false,
    // formData: {
    //     well_type: [],
    //     production_type: [],
    //     well_status: [],
    //     drill_type: [],
    // },
    formData: null,
    file: null,
    geometry: "",
    epsg: "",
    csvApiFileName: "",
    csvApiFileSize: 0,
    csvApiFileData: [],
    csvApiUnmatchedFileData: [],
    csvApiMatchedFileData: [],
    csvApiFileLoading: true,
    deleteCartItemModal: false,
    deleteItemId: null,
    deleteItemType: null,
    sub_total: 0,
    notInPlan: false,
    byBasinTabData: [],
    byCountyTabData: [],
    apiFileWellApiList: [],
};

export const cartBasinToFilterSlice = createSlice({
    name: "cartbasinfilter",
    initialState,
    reducers: {
        setInitialState(state, action: PayloadAction) {
            return action.payload;
        },
        setCsvApiFileLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                csvApiFileLoading: action.payload,
            };
        },
        setCsvApiFileData(state, action: PayloadAction<csvApiDataObj[]>) {
            return {
                ...state,
                csvApiFileData: action.payload,
            };
        },
        setNotInPlan(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                notInPlan: action.payload,
            };
        },
        setByBasinInTabData(state, action: PayloadAction<BasinObjApiList[]>) {
            return {
                ...state,
                byBasinTabData: action.payload,
            };
        },
        setByCountyInTabData(state, action: PayloadAction<CountyObjApiList[]>) {
            return {
                ...state,
                byCountyTabData: action.payload,
            };
        },
        setCsvApiFileName(state, action: PayloadAction<string>) {
            return {
                ...state,
                csvApiFileName: action.payload,
            };
        },
        setCsvApiFileSize(state, action: PayloadAction<number>) {
            return {
                ...state,
                csvApiFileSize: action.payload,
            };
        },
        setCsvApiFile(state, action: PayloadAction<File | null>) {
            return {
                ...state,
                file: action.payload,
            };
        },
        setFileToOpen(state, action: PayloadAction<number>) {
            return {
                ...state,
                fileToOpen: action.payload,
            };
        },
        setOpenModalAFterUploadModal(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                openModalAFterUploadModal: action.payload,
            };
        },
        setGeometry(state, action: PayloadAction<string>) {
            return {
                ...state,
                geometry: action.payload,
            };
        },
        setEpsg(state, action: PayloadAction<string>) {
            return {
                ...state,
                epsg: action.payload,
            };
        },
        setFormData(
            state,
            action: PayloadAction<CartBasinFilterFormData | null>
        ) {
            return {
                ...state,
                formData: action.payload,
            };
        },
        setDeleteItemId(state, action: PayloadAction<number | null>) {
            return {
                ...state,
                deleteItemId: action.payload,
            };
        },
        setDeleteCartItemModal(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                deleteCartItemModal: action.payload,
            };
        },
        setDeleteCartItemType(state, action: PayloadAction<number | null>) {
            return {
                ...state,
                deleteItemType: action.payload,
            };
        },
        setApiFileWellApiList(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                apiFileWellApiList: action.payload,
            };
        },
        setShapeFileModal(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                shapeFileModal: action.payload,
            };
        },
        setIsChooseColumn(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isChooseColumn: action.payload,
            };
        },
        setIsExportOther(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isExportOther: action.payload,
            };
        },
    },
});

export default cartBasinToFilterSlice;
