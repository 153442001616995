import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    GAS, OIL,
    LINE_CHART_XAXIS_FILTERS, getHeaderOfCSV,
} from "../../utils/helper";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { handleFreeTrialDownAlertMsgModal } from "../store/actions/modal-actions";
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { getLineChartData, mergeAndSortOilGasData } from "../../Helper/commonFunction";

type PropsType = {
    iconSize: string;
}
type TypeForecastData = {
    well_api: string,
    production_date?: string,
    production_month?: number,
    'Liquids bbl/1000ft'?: number,
    'Liquids Stream bbl/1000ft'?: number,
    'Cum Liquids bbl/1000ft'?: number,
    'Cum Liquids Stream bbl/1000ft'?: number,
    'Liquids bbl/'?: number,
    'Liquids Stream bbl'?: number,
    'Cum Liquids bbl'?: number,
    'Cum Liquids Stream bbl'?: number,
    'Gas bbl/1000ft'?: number,
    'Gas Stream bbl/1000ft'?: number,
    'Cum Gas bbl/1000ft'?: number,
    'Cum Gas Stream bbl/1000ft'?: number,
    'Gas bbl/'?: number,
    'Gas Stream bbl'?: number,
    'Cum Gas bbl'?: number,
    'Cum Gas Stream bbl'?: number,
    DCA?: number,
    "Data Type": string
}
const DownloadProductionLink = (props: PropsType) => {
    const { iconSize } = props;


    const dataList = useAppSelector(state => state.wellsAndRigs.analyticsData.forecastingData.dataList)
    const wellsAndRigs = useAppSelector(state => state.wellsAndRigs)
    const company_configs = useAppSelector(state => state.auth.user.company_configs)

    const dispatch = useAppDispatch();
    const producingTime = wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"];

    return (
        <>

            <Link to="" onClick={() => {
                if (company_configs.free_trial_period_enabled && !company_configs.download_enabled) {
                    dispatch(handleFreeTrialDownAlertMsgModal(true))
                    return
                }
                let oilDataForDownload: any[] = getLineChartData(
                    dataList,
                    wellsAndRigs.analyticsData.normalized,
                    wellsAndRigs.analyticsData.type,
                    wellsAndRigs.analyticsData.prodLiq,
                    wellsAndRigs.analyticsData.prodGas,
                    wellsAndRigs.analyticsData.gasAggDate,
                    wellsAndRigs.analyticsData.gasAggMonth,
                    wellsAndRigs.analyticsData.liqAggDate,
                    wellsAndRigs.analyticsData.liqAggMonth,
                    OIL,
                    OIL,
                    GAS,
                    wellsAndRigs.selectedWellRigOrPermitList,
                    wellsAndRigs.fullScrnAnalytics,
                    wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true,
                    wellsAndRigs.analyticsData.action,
                    wellsAndRigs.analyticsData.apiList,
                    wellsAndRigs.analyticsData.selectedForecastPoints
                )
                let gasDataForDownload: any[] = getLineChartData(
                    dataList,
                    wellsAndRigs.analyticsData.normalized,
                    wellsAndRigs.analyticsData.type,
                    wellsAndRigs.analyticsData.prodLiq,
                    wellsAndRigs.analyticsData.prodGas,
                    wellsAndRigs.analyticsData.gasAggDate,
                    wellsAndRigs.analyticsData.gasAggMonth,
                    wellsAndRigs.analyticsData.liqAggDate,
                    wellsAndRigs.analyticsData.liqAggMonth,
                    GAS,
                    OIL,
                    GAS,
                    wellsAndRigs.selectedWellRigOrPermitList,
                    wellsAndRigs.fullScrnAnalytics,
                    wellsAndRigs.analyticsData.xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true,
                    wellsAndRigs.analyticsData.action,
                    wellsAndRigs.analyticsData.apiList,
                    wellsAndRigs.analyticsData.selectedForecastPoints)

                if (wellsAndRigs.openForeCast) {
                    /** If open forecast is true, then we have a forecast or type curve situation. otherwise, its the
                     * standard, 'download all the production data'
                     */
                    const getProductionColumn = (stream: boolean) => {
                        if (wellsAndRigs.fullScrnAnalyticsType === "oil") {
                            if (wellsAndRigs.analyticsData.normalized && wellsAndRigs.analyticsData.type === "monthlyTab") {
                                return `Liquids ${stream ? 'Stream ' : ''}bbl/1000ft`
                            } else if (wellsAndRigs.analyticsData.normalized) {
                                return `Cum Liquids ${stream ? 'Stream ' : ''}bbl/1000ft`
                            } else if (wellsAndRigs.analyticsData.type === "monthlyTab") {
                                return `Liquids ${stream ? 'Stream ' : ''}bbl`
                            } else {
                                return `Cum Liquids ${stream ? 'Stream ' : ''}bbl`
                            }
                        } else {
                            // produciton type is gas
                            if (wellsAndRigs.analyticsData.normalized && wellsAndRigs.analyticsData.type === "monthlyTab") {
                                return `Gas ${stream ? 'Stream ' : ''}mcf/1000ft`
                            } else if (wellsAndRigs.analyticsData.normalized) {
                                return `Cum Gas ${stream ? 'Stream ' : ''}mcf/1000ft`
                            } else if (wellsAndRigs.analyticsData.type === "monthlyTab") {
                                return `Gas ${stream ? 'Stream ' : ''}mcf`
                            } else {
                                return `Cum Gas ${stream ? 'Stream ' : ''}mcf`
                            }
                        }
                    }
                    let tempData: {
                        well_api: string,
                        production_date?: string,
                        production_month?: number,
                        'Liquids bbl/1000ft'?: number,
                        'Liquids Stream bbl/1000ft'?: number,
                        'Cum Liquids bbl/1000ft'?: number,
                        'Cum Liquids Stream bbl/1000ft'?: number,
                        'Liquids bbl/'?: number,
                        'Liquids Stream bbl'?: number,
                        'Cum Liquids bbl'?: number,
                        'Cum Liquids Stream bbl'?: number,
                        'Gas bbl/1000ft'?: number,
                        'Gas Stream bbl/1000ft'?: number,
                        'Cum Gas bbl/1000ft'?: number,
                        'Cum Gas Stream bbl/1000ft'?: number,
                        'Gas bbl/'?: number,
                        'Gas Stream bbl'?: number,
                        'Cum Gas bbl'?: number,
                        'Cum Gas Stream bbl'?: number,
                        DCA?: number,
                        "Data Type": string
                    }[] = [];

                    const createTempData = (originalData: any[], originalTempData: typeof tempData) => {
                        let hashObj: { [key: string]: TypeForecastData } = {}
                        if (originalData.length > 0 && originalData[0]?.values) {
                            let nonZero = false;
                            let index = 0;
                            let wellName = ''
                            let lateral_length = 0

                            wellsAndRigs.selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                                if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === originalData[0]?.name) {

                                    wellName = `${well.well_api} - ${well.well_name}`
                                    lateral_length = well.lateral_length
                                }
                            })
                            if (!wellName) {
                                wellName = originalData[0]?.name
                            }
                            if (wellsAndRigs.analyticsData.action && wellsAndRigs.analyticsData.action.toLowerCase() !== "none") {
                                wellName = wellsAndRigs.analyticsData.action
                            }
                            originalData[0].values.forEach((oil_obj: { production_date?: string, production_qty: number, production_month?: number }) => {
                                if (
                                    oil_obj.production_qty === 0 &&
                                    !nonZero
                                    //  && lateral_length
                                ) {
                                } else {
                                    nonZero = true
                                    let newProdObj: any = { well_api: wellName }
                                    newProdObj[getProductionColumn(false)] = oil_obj.production_qty.toFixed(2)
                                    newProdObj[getProductionColumn(true)] = oil_obj.production_qty.toFixed(2)
                                    newProdObj["Data Type"] = "historical"
                                    const newHashObj = { ...hashObj }
                                    if (producingTime && oil_obj.production_month) {
                                        newProdObj.production_month = oil_obj.production_month
                                        newHashObj[oil_obj.production_month.toString()] = newProdObj
                                    } else if (oil_obj.production_date) {
                                        newProdObj.production_date = oil_obj.production_date
                                        newHashObj[oil_obj.production_date] = newProdObj
                                    }
                                    hashObj = newHashObj
                                }
                            })


                        }
                        if (dataList && dataList?.length > 0 && dataList[0]?.values) {
                            dataList[0].values.forEach((dataListObj: { production_date?: string, production_qty: number, production_month?: number }) => {
                                let updatedHashObj: any = {}
                                if (producingTime && dataListObj.production_month) {
                                    updatedHashObj = { ...hashObj }[dataListObj.production_month] || {}
                                } else if (dataListObj.production_date) {
                                    updatedHashObj = { ...hashObj }[dataListObj.production_date] || {}
                                }

                                if (!updatedHashObj["Data Type"]) {
                                    let wellName = dataList[0].name
                                    wellsAndRigs.selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                                        if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === dataList[0].name) {
                                            wellName = `${well.well_api} - ${well.well_name}`
                                        }
                                    })
                                    updatedHashObj["Data Type"] = "forecast"
                                    updatedHashObj[getProductionColumn(true)] = dataListObj.production_qty.toFixed(2)
                                    updatedHashObj.well_api = wellName
                                    if (producingTime) {
                                        updatedHashObj.production_month = dataListObj.production_month
                                    } else {
                                        updatedHashObj.production_date = dataListObj.production_date
                                    }

                                }
                                updatedHashObj.DCA = dataListObj.production_qty.toFixed(2)
                                const newHashObj = { ...hashObj }
                                if (producingTime && dataListObj.production_month) {
                                    newHashObj[dataListObj.production_month.toString()] = updatedHashObj
                                } else if (dataListObj.production_date) {
                                    newHashObj[dataListObj.production_date] = updatedHashObj
                                }
                                hashObj = newHashObj

                            })
                        }
                        for (const [key, value] of Object.entries(hashObj)) {
                            originalTempData.push(value)
                        }
                        if (producingTime) {
                            originalTempData.sort((a: any, b: any) => a.production_month - b.production_month);
                        } else {
                            originalTempData.sort((a: any, b: any) => new Date(a.production_date).getTime() - new Date(b.production_date).getTime());
                        }
                        return originalTempData
                    }

                    // we're going to make a hash object with either the production_data or 
                    // production month as keys and the temp data as the value

                    if (wellsAndRigs.fullScrnAnalyticsType === "oil") {
                        tempData = createTempData(oilDataForDownload, tempData)

                    } else {
                        // production type is gas
                        tempData = createTempData(gasDataForDownload, tempData)
                    }

                    exportFromJSON({
                        data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                            "MMM-DD-YYYY, h:mm:ss a"
                        )}`,
                        exportType: "csv",
                        fields: [
                            (producingTime ? "production_month" : "production_date"),
                            "well_api",
                            getProductionColumn(false),
                            "DCA",
                            getProductionColumn(true),
                            "Data Type"
                        ],
                        beforeTableEncode: entries => {
                            return entries.map(
                                ({ fieldName, fieldValues }) => {
                                    return { fieldName: getHeaderOfCSV(fieldName, false, wellsAndRigs.analyticsData.normalized, wellsAndRigs.analyticsData.type, wellsAndRigs.analyticsData.action), fieldValues };
                                }
                            )
                        }
                    })
                } else {
                    const mergeDataForDownload = mergeAndSortOilGasData(oilDataForDownload, gasDataForDownload, producingTime, wellsAndRigs.analyticsData.action)
                    let tempData: { well_api: string, production_date: string, production_quantity_oil: string, production_quantity_gas: string, production_month?: number }[] = [];

                    // eslint-disable-next-line
                    mergeDataForDownload.forEach((item: any) => {
                        let nonZero = false;
                        let index = 0;
                        let wellName = ''
                        wellsAndRigs.selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                            if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === item.name) {
                                wellName = `${well.well_api} - ${item.name}`
                            }
                        })
                        if (!wellName) {
                            wellName = item.name
                        }
                        if (wellsAndRigs.analyticsData.action && wellsAndRigs.analyticsData.action.toLowerCase() !== "none") {
                            wellName = wellsAndRigs.analyticsData.action
                        }

                        item.values.forEach((_item: any) => {
                            if (
                                (_item.production_qty === 0 ||
                                    _item.production_qty === null) &&
                                !nonZero
                            ) {
                            } else {
                                !nonZero && (nonZero = true);
                                tempData.push({
                                    well_api: wellName,
                                    production_date: `${moment(_item.date).format("MM-DD-YYYY")}`,
                                    production_quantity_oil: (_item.production_quantity_oil ? _item.production_quantity_oil.toString() : '').replace(/\D/g, ''),
                                    production_quantity_gas: (_item.production_quantity_gas ? _item.production_quantity_gas.toString() : '').replace(/\D/g, ''),
                                    production_month: index
                                })
                                index = index + 1
                            }
                        })
                    })

                    exportFromJSON({
                        data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                            "MMM-DD-YYYY, h:mm:ss a"
                        )}`,
                        exportType: "csv",
                        fields: [
                            (producingTime ? "production_month" : "production_date"),
                            "well_api",
                            "production_quantity_oil",
                            "production_quantity_gas",
                        ],
                        beforeTableEncode: entries => entries.map(
                            ({ fieldName, fieldValues }) => {
                                return { fieldName: getHeaderOfCSV(fieldName, false, wellsAndRigs.analyticsData.normalized, wellsAndRigs.analyticsData.type, wellsAndRigs.analyticsData.action), fieldValues };
                            }
                        )
                    })
                }

            }}>
                {iconSize === "large" ? (<div className="downloadLink">
                    <img src="images/download.svg" alt="downloadImage" className="downloadImage" />
                </div>) : (<img src="images/download.svg" alt="downloadImage" />)}

            </Link >

        </>
    );
};

export default DownloadProductionLink;
