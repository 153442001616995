import React, { useMemo } from "react";
import AlertsTabContent from "../alert/AlertsTabContent";
import AlertsTabHeading from "../alert/AlertsTabHeading";
import { useLocation } from "react-router-dom";
import FilesTabHeading from "../files/FilesTabHeading";
import FilesTabContent from "../files/FilesTabContent";
import { useAppSelector } from "../hooks/redux-hooks";
import LottieAnimationforTable from "./LottieAnimationforTable";
import CartBasinFilterSection from "../cartBasinToCounty/CartBasinFilterSection";
import { alertsPathname, aoiPathname, filesPathname, searchPathname } from "../../utils/helper";

function SideContainer() {
    const location = useLocation();

    const sideContentLoader = useAppSelector(state => state.modal.sideContentLoader);
    const leftTab = useAppSelector(state => state.cartBasinToCounty.leftTab);

    const SideComponentMemo = useMemo(() => {
        switch (location.pathname) {
            case `${alertsPathname}`: return <>
                <AlertsTabHeading />
                <div className="tabSection segmentsSection">
                    <div className="tab-content" id="myTabContentalert">
                        <AlertsTabContent />
                    </div>
                </div>
                {sideContentLoader && <LottieAnimationforTable />}
            </>
            // case `${segmentsPathname}`: return <>
            //     <SegmentsTabHeading />
            //     <SegmentsTabContent />
            //     {sideContentLoader && <LottieAnimationforTable />}
            // </>
            // case `${saveFiltersPathname}`: return <>
            //     <SegmentsTabHeading />
            //     <SegmentsTabContent />
            //     {sideContentLoader && <LottieAnimationforTable />}
            // </>

            case `${filesPathname}`: return <>
                <FilesTabHeading />
                <FilesTabContent />
                {sideContentLoader && <LottieAnimationforTable />}
            </>;

            case `${searchPathname}`: return <CartBasinFilterSection />;

            // case `${aoiPathname}`: return <AoiSideCon />
            case `${aoiPathname}`: return <CartBasinFilterSection />
            default: return <></>;
        }
        // eslint-disable-next-line 
    }, [location.pathname, sideContentLoader])
    return (
        <>
            {location.pathname === searchPathname || location.pathname === aoiPathname ? SideComponentMemo : <div className={`segmentsCon ${!leftTab ? "isHide" : ''}`}>
                {SideComponentMemo}
            </div>}
        </>
    );
}

export default SideContainer;
