// Type definitions for Appcues
type UserAttributes = {
    first_name?: string;
    last_name?: string;
    email?: string;
    user_id?: number;
};

// Ensure the Appcues array is initialized
if (!(window as any).appcues) {
    (window as any).appcues = [];
}

/**
 * Initializes Appcues with user information.
 * @param userId - The unique identifier for the user.
 * @param userAttributes - Additional user attributes to send to Appcues.
 */
export const initializeAppcues = (
    userId: number | null,
    userAttributes: UserAttributes = {}
) => {
    if (userId) {
        (window as any).Appcues.identify(userId, userAttributes);
    } else {
        (window as any).appcues.push(["stop"]); // Stop Appcues if no user ID
    }
};

/**
 * Shows a specific Appcues flow.
 * @param flowId - The ID of the flow to display.
 */
export const showAppcuesFlow = (flowId: string) => {
    (window as any).appcues.push(["show", flowId]);
};

/**
 * Stops any active Appcues flows.
 */
export const stopAppcues = () => {
    (window as any).appcues.push(["stop"]);
};
