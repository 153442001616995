// useAppcuesDomainControl.ts
import { useEffect } from "react";

// List of allowed domains for Appcues
const ALLOWED_DOMAINS = [
    "localhost:3000",
    "data-dev.energydomain.com",
    "data.energydomain.com",
    "data-staging.energydomain.com",
];

/**
 * Custom hook for controlling Appcues based on the current domain.
 */
export const useAppcuesDomainControl = () => {
    useEffect(() => {
        const currentDomain = window.location.hostname;

        if (!ALLOWED_DOMAINS.includes(currentDomain)) {
            (window as any).appcues.push(["stop"]); // Stop Appcues if not on an allowed domain
        }
    }, []);
};
