import React from "react";
import { Controller } from "react-hook-form";
import { AsyncSelectProps } from "../models/page-props";
import { AsyncPaginate } from "react-select-async-paginate";
import { Tooltip } from 'react-tooltip'

function AsyncSelect({
    control,
    name,
    errorMsg,
    loadOptions,
    labelClassName,
    label,
    className,
    ...rest
}: AsyncSelectProps) {

    return (
        <>
            {label && <label className={labelClassName}>{label}</label>}
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, ref, ...field } }) => {
                    if (name.includes("wellMatching") && value && value.length > 0 && value[0]?.label) {
                        return (<span data-tooltip-id="Async-Select-tooltip"
                            data-tooltip-html={`<div><span>${value[0]?.label}</span></div>`}
                            data-tooltip-place={name.includes('wellMatching[0]') ? "bottom" : "top"}
                            data-tooltip-variant="dark"><AsyncPaginate
                                value={value}
                                loadOptions={loadOptions}
                                menuShouldScrollIntoView={false}
                                onChange={onChange}
                                className={className}
                                selectRef={ref}
                                {...rest}
                            /></span>)
                    }
                    return (<AsyncPaginate
                        value={value}
                        loadOptions={loadOptions}
                        menuShouldScrollIntoView={false}
                        onChange={onChange}
                        className={className}
                        selectRef={ref}
                        {...rest}
                    />
                    )
                }}
            />
            {errorMsg && <span className={`error`}>{errorMsg}</span>}
            <Tooltip id="Async-Select-tooltip" />
        </>
    );
}

export default AsyncSelect;
