import React from 'react'
import { Button } from "react-bootstrap";
import GlobalModal from "../GlobalModal";
import GoNational from '../../cartSelectBasin/GoNational';


const BasinSuggestionModal = (props: { show: boolean, handleClose: () => void, handleProceedToBtnClick: () => void, go_nation_flag?: boolean }) => {
    const { handleClose, handleProceedToBtnClick, show, go_nation_flag } = props;
    return (
        <GlobalModal
            show={show}
            enableFooter={true}
            center={true}
            // modalSize={"lg"}
            onHide={handleClose}
            contentClass={"subscribeModal"}
            footerClass={"action-footer"}
            footRender={
                <>
                    <Button variant="btn btn-outline-white" onClick={handleClose}>
                        No, Thank You
                    </Button>
                    <Button variant="btn btn-green" onClick={handleProceedToBtnClick}>
                        Yes, Upgrade Me
                    </Button>
                </>
            }
        >
            <div className="seggestionModalContent">
                {/* <figure className='subsImage'><img src="images/subs-img.svg" alt="" /></figure> */}
                {/* <h3 className='text-center'>Maximize Your Insights with a Basin Subscription!</h3> */}
                {
                    go_nation_flag ?
                        <GoNational hideBtn={true} />
                        :
                        <>
                            <h3>Upgrade to Basin!</h3>
                            <p>Your cart has met or exceeded our Basin level subscription price, so we recommend upgrading to get the most out of your subscription. If you decline, the last item added to your cart will be removed.</p>
                            <br />
                        </>
                }
            </div>
        </GlobalModal >
    );
};

export default BasinSuggestionModal;
