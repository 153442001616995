// useAppcuesTracking.ts
import { useEffect } from "react";
import { initializeAppcues } from "../../Helper/appcues";

interface User {
    first_name?: string;
    last_name?: string;
    email?: string;
    user_id?: number;
}

/**
 * Custom hook for tracking user information with Appcues.
 * @param user - The current user object.
 */
export const useAppcuesTracking = (user: User | null) => {
    useEffect(() => {
        if (user?.user_id) {
            // console.log("User logged in, calling Appcues identify:", user); // Debug log
            // Ensure user identification
            initializeAppcues(user?.user_id, {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                user_id: user.user_id,
            });
        } else {
            // console.log("No user logged in, stopping Appcues tracking."); // Debug log
            initializeAppcues(null); // Stop tracking if no user data
        }
    }, [user]);
};
