import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux-hooks';

function Headers() {
    const [state, setState] = useState({
        isActive: false
    });
    const { isActive } = state;
    const showMenu = () => {
        setState((prev) => ({ ...prev, isActive: !isActive }));
    };
    const token = useAppSelector((state) => state.auth.user.access_token)

    return (
        <>
            <div className={isActive ? "mobile-menu show" : "mobile-menu"}>
                <ul>
                    <li>
                        <a onClick={(e) => e.preventDefault()} href="void:(0)">Platform</a>
                    </li>
                    <li>
                        <a onClick={(e) => e.preventDefault()} href="void:(0)">Features</a>
                    </li>
                    <li>
                        <a onClick={(e) => e.preventDefault()} href="void:(0)">Who We Serve</a>
                    </li>
                    <li>
                        <a onClick={(e) => e.preventDefault()} href="void:(0)">Resources</a>
                    </li>
                    {!token ? <><li>
                        <Link to="/login">Login</Link>
                    </li>
                        <li>
                            <Link to="/sign-up">Sign Up</Link>
                        </li> </> : <></>
                    }
                </ul>
            </div>


            <header className="main-header">
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src="images/logo.svg" alt="logo" />
                        </Link>
                    </div>
                    <nav className="head-right">
                        <div className="menu-con">
                            <ul>
                                <li>
                                    <a onClick={(e) => e.preventDefault()} href="void:(0)">Platform</a>
                                </li>
                                <li>
                                    <a onClick={(e) => e.preventDefault()} href="void:(0)">Features</a>
                                </li>
                                <li>
                                    <a onClick={(e) => e.preventDefault()} href="void:(0)">Who We Serve</a>
                                </li>
                                <li>
                                    <a onClick={(e) => e.preventDefault()} href="void:(0)">Resources</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <nav className="head-right">
                        {!token ? <div className="menu-con">
                            <ul className="login-signup">
                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                                <li>
                                    <Link to="/sign-up" className="btn btn-outline-white">Create Account</Link>
                                </li>
                            </ul>
                        </div> : <></>}
                        <button className={isActive ? "hemburger-button active" : "hemburger-button"} onClick={showMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Headers