import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { actionType, settingsPopupNavMenuItems } from "../../utils/helper";
import { logUserAction, logout } from "../store/actions/auth-actions";
import { showCheckOutModal } from "../store/actions/modal-actions";
import { toast } from "react-toastify";
import { getNameInitials } from "../../Helper/commonFunction";

function SettingsComponent() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        auth: {
            user: {
                first_name,
                last_name,
                company_data,
                signin_as,
                profile_pic,
                access_token,
                company_configs: { trial_expired, free_trial_period_enabled }
            },
        },
        cartSelectBasinCounty: {
            cartListItems,
        }
    } = useAppSelector((state) => state);
    const { company_logo, company_name } = company_data || {};


    return (
        <>
            <div className={"avatar-section"}>
                <div className="settings-left">
                    <figure>
                        {company_logo ?
                            <div className="profile-icon">
                                <img src={
                                    company_logo
                                        ? `${process.env.REACT_APP_ED_DATA_CDN_API}/company_logo/${company_logo}`
                                        : "images/profile-icon.svg"
                                } alt="Co." />
                            </div>
                            :
                            company_name ? getNameInitials(company_name) : "CO"
                        }
                    </figure>
                </div>

                <div className="settings-right">
                    <figure>
                        <img src={
                            profile_pic
                                ? `${process.env.REACT_APP_ED_DATA_CDN_API}/profile_pic/${profile_pic}`
                                : "images/profile-pic.png"
                        } alt="profile-pic" />
                    </figure>
                </div>
            </div>

            <div className={"profile-name-section"}>
                <div className="settings-left">
                    <div className="company-profile">
                        {company_name ? `${company_name}` : "Company"}
                    </div>
                </div>

                <div className="settings-right">
                    <div className="user-profile">
                        {first_name}
                    </div>
                </div>
            </div>

            <div className={"settings-links-section"}>
                <div className="settings-left">
                    <div className={"company-settings"}>
                        {settingsPopupNavMenuItems.map((item, index) => {
                            return (
                                signin_as === item.signin_as && (
                                    <Link key={index}
                                        to={(free_trial_period_enabled && trial_expired) ? "" : item.pathname}
                                        onClick={(e) => {
                                            if (item.label === "Settings") {
                                                dispatch(
                                                    logUserAction({
                                                        action_type: actionType["view_settings"],
                                                        action_log_detail: "visited Settings page.",
                                                    })
                                                );
                                            }
                                            dispatch(
                                                logUserAction({
                                                    action_type: actionType["page_view"],
                                                    action_log_detail: `page: ${item.pathname}`,
                                                })
                                            )

                                            if (free_trial_period_enabled && trial_expired) {
                                                e.preventDefault();
                                                cartListItems.length && dispatch(showCheckOutModal());
                                                toast.info("Please subscribe to plan.");
                                            }
                                        }}>
                                        {item.label}
                                    </Link>
                                )
                            );
                        }
                        )}
                    </div>
                </div>

                <div className="settings-right">
                    <div className={"user-settings"}>
                        <Link
                            to={free_trial_period_enabled && trial_expired ? "" : "/my-settings"}
                            onClick={(e) => {
                                if (free_trial_period_enabled && trial_expired) {
                                    e.preventDefault()
                                    cartListItems.length && dispatch(showCheckOutModal());
                                    toast.info("Please subscribe to plan.")
                                }
                            }}>My Settings
                        </Link>
                    </div>

                    <div className={"logout"} onClick={() => {
                        navigate(location.pathname);
                    }}>
                        <a href="void:(0)"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(logout(access_token));
                            }}>Log out
                            <i className="fa-solid fa-right-from-bracket" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingsComponent;
