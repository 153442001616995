import React, { useEffect } from "react";
import SavedTabContent from "./SavedTabcontent";
import PopularTabContent from "./PopularTabContent";
import GenAoiSettTabContent from "./GenAoiSettTabContent";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation } from "react-router-dom";
import { handleSelectedAoiData, handleSelectedWellRigOrPermitUIDList } from "../store/actions/wells-rigs-action";
import { clearAoiNotiData, setAoiNameSelForEdit, toggleSettDrawer } from "../store/actions/aoi-actions";
import { toggleLeftTab } from "../store/actions/cart-basin-to-county-actions";

function AoiTabContent() {
    const { aoi: { savedAoiData, toggleSettingDrawer } } = useAppSelector((state) => state);
    const location = useLocation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        let aoi_id = location?.state?.aoi_id;
        let aoi_id_details = savedAoiData.find((item) => item['id'] === Number(aoi_id));
        if (savedAoiData.length && aoi_id && typeof aoi_id_details === "object") {
            dispatch(handleSelectedWellRigOrPermitUIDList([
            ]))
            dispatch(handleSelectedAoiData({ aoi_id: aoi_id }));

            dispatch(
                setAoiNameSelForEdit({ aoi_id: aoi_id, aoi_name: aoi_id_details['aoi_name'], buffer_distance: aoi_id_details['buffer_distance'] })
            );
            dispatch(clearAoiNotiData());
            !toggleSettingDrawer && dispatch(toggleSettDrawer());
            window.history.replaceState({}, document.title);
        }
    }, [savedAoiData])
    return (
        <div className={savedAoiData.length > 0 ? "tabSection cardsection" : "tabSection"}>
            <Scrollbars
                // autoHeight
                className='cardsection-scroll'
                autoHeightMin={0}
                renderThumbVertical={props => < div {...props} className="thumb-vertical" />}
                renderTrackVertical={props => < div {...props} className="track-vertical" />}
            >
                <div className="tab-content" id="myTabContentaoi">
                    <SavedTabContent />
                    {/* <PopularTabContent /> */}
                    {/* <GenAoiSettTabContent /> */}
                </div>
            </Scrollbars>
        </div>
    );
}

export default AoiTabContent;
