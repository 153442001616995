import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HomeViewProps } from "../models/page-props";
import Headers from "../common/Headers";

const HomeView = (props: HomeViewProps) => {


  return (
    <>
      <Headers />
      <div className="wapper">
        <div className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="unlock-content">
                  <h1>
                    Unlock the Power
                    <br /> of Your Oil &amp; Gas <span>Data</span>
                  </h1>
                  <div className="text-block-cont">
                    <p>Propel your market foresight with Energy Domain Data—access premier well analytics, forecasting, and type curve insights. Make informed decisions with our comprehensive data platform. Shape your operational future today.</p>

                  </div>
                  <Link className="btn btn-primary" to="/sign-up" state={{ isFreeTrial: 1 }}>Start Free Trial</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeView;
