import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import axios from "../../../utils/axios";
import cartSelectBasinCountySlice from "../reducers/cart-select-basin-county-slice";

import { toast } from "react-toastify";
import {
    CalculateTaxFormData,
    CartListItemsType,
    CartSelectBasinCountyInitialValue,
    CreateSubscriptionAlreadySubscribedFormData,
    CreateSubscriptionFormData,
    PreviewInvoiceLineItems,
    CreateSubThroughBankAccFormData,
    DowngradeSeatSubscriptionFormData,
    FinalSubscriptionFormData,
    RemoveCartItemFormData,
    InvoiceLineItemsAndTotal
} from "../../models/redux-models";
import { CountyDataResponse } from "../../models/page-props";
import {
    BasinCountySearchListFormData,
    CreateSubscriptionReturnType,
    FetchWellNameSugReturnType,
    GetDetailsCountyBasinFormData,
    PromoCodeReturn,
    RemoveItemFromCartReturnType,
    ReturnMsgAndStatus,
    getApiListAfterSubReturnType,
} from "../../models/submit-form";
import { hideSiteLoader, showSiteLoader } from "./modal-actions";
import {
    config,
    errToast,
    formatUSDCurrency,
    tokenIsValid,
} from "../../../utils/helper";
import { AxiosError } from "axios";
export const cartSelectBasinCountyActions = cartSelectBasinCountySlice.actions;

let abortController = new AbortController();
//load search List
export const loadSearchList = (
    token: string,
    formData: BasinCountySearchListFormData
): ThunkAction<void, RootState, unknown, AnyAction> => {
    const { search, category } = formData;
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.get(
                `/api-subscriptions/get-suggetion?cat=${category}&search=${search}`,
                config
            );
            const { status, msg, data } = res.data;

            if (status === 200) {
                if (data) {
                    if (category === "basin") {
                        dispatch(
                            cartSelectBasinCountyActions.loadBasinSearchList(
                                data
                            )
                        );
                    } else {
                        let tempData: CartSelectBasinCountyInitialValue["countySearchList"] =
                            [];
                        let stateArray: string[] = [];
                        let stateISOCodeArray: string[] = [];
                        data?.forEach((item: CountyDataResponse) => {
                            if (!stateArray.includes(item.state_name)) {
                                stateArray.push(item.state_name);
                                stateISOCodeArray.push(item.iso_code);
                            }
                        });
                        stateArray?.forEach((item, index) => {
                            let temp: string[] = data
                                .filter(
                                    (_item: CountyDataResponse) =>
                                        _item.state_name === item
                                )
                                .map(
                                    (item: CountyDataResponse) =>
                                        item.county_name
                                );
                            tempData.push({
                                state_name: item,
                                county_name: temp,
                                iso_code: stateISOCodeArray[index],
                            });
                        });
                        dispatch(
                            cartSelectBasinCountyActions.loadCountySearchList(
                                tempData
                            )
                        );
                    }
                }
            } else {
                toast.error(msg);
            }
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};

//clearSearchList
export const clearSearchList = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.clearSearchList());
    };
};

//getBasinCountyDetails
export const getBasinCountyDetails = (
    token: string,
    formData: GetDetailsCountyBasinFormData
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        const {
            auth: {
                user: {
                    company_configs: {
                        trial_expired,
                        free_trial_period_enabled,
                    },
                },
            },
            subscriptionSettings: { upgrade_subscription },
            cartSelectBasinCounty: { yearly }
        } = getState();

        try {
            const res = await axios.get(
                `/api-subscriptions/get-cart-item?cat=${formData.category
                }&search=${formData.search}&billing_interval=${yearly ? 'Y' : 'M'}${formData.category === "county"
                    ? `&state=${formData?.state}`
                    : ""
                }${free_trial_period_enabled &&
                    !trial_expired &&
                    !upgrade_subscription
                    ? `&plan=free`
                    : ""
                }`,
                config
            );
            const { status, msg, data } = res.data;

            if (status === 200) {
                if (data && Array.isArray(data)) {
                    if (formData.category === "county") {
                        dispatch(
                            handleLastCountyBasinName(data[0]["county_basin"])
                        );
                    }
                    await dispatch(getCartDetails(token));
                }
            } else {
                toast.error(msg);
                dispatch(hideSiteLoader());
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        } finally {
            dispatch(hideSiteLoader());
        }
    };
};

//add item in cartList
export const loadCartItems = (val: {
    cart_list_items_monthly: CartListItemsType;
    cart_list_items_yearly: CartListItemsType;
    yearlyDiscountPercent: CartSelectBasinCountyInitialValue["yearlyDiscountPercent"];
    yearlyBillToday: CartSelectBasinCountyInitialValue["yearlyBillToday"];
    monthlyBillToday: CartSelectBasinCountyInitialValue["monthlyBillToday"];

}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.loadCartItems(val));
    };
};

//removeCartItem
export const removeCartItems = (
    token: string,
    formData: RemoveCartItemFormData
): ThunkAction<
    Promise<RemoveItemFromCartReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const config = {
                data: {
                    ...formData,
                    ...("sub_total" in formData && {
                        sub_total: Number(formData.sub_total.toFixed(2)),
                    }),
                },
                headers: { "Content-Type": "application/json" },
            };
            const res = await axios.delete(
                `/api-subscriptions/temp-cart-item`,
                config
            );
            const { status, msg, data, cart_list_items, bill_today } = res.data;
            if (status === 200) {
                if ("sub_total" in formData) {
                    dispatch(clearCartItemsList());
                    dispatch(clearCartItemsTotalTax());
                    if (data?.data) {
                        dispatch(
                            cartSelectBasinCountyActions.loadCartItemTotalTax({
                                totalTax: data?.data.tax_amount_exclusive / 100,
                                tax_percentage: Number(
                                    data?.data?.tax_breakdown[0]
                                        .tax_rate_details?.percentage_decimal
                                ),
                            })
                        );
                    }
                } else {
                    const state = getState()
                    const yearly = state.cartSelectBasinCounty.yearly
                    dispatch(cartSelectBasinCountyActions.loadCartItems({
                        cart_list_items_monthly: yearly ? state.cartSelectBasinCounty.cartListItems : cart_list_items,
                        cart_list_items_yearly: yearly ? cart_list_items : state.cartSelectBasinCounty.cartListItemsYearly,
                        yearlyDiscountPercent: state.cartSelectBasinCounty.yearlyDiscountPercent,
                        yearlyBillToday: yearly ? bill_today : state.cartSelectBasinCounty.yearlyBillToday,
                        monthlyBillToday: yearly ? state.cartSelectBasinCounty.monthlyBillToday : bill_today
                    }));
                }
                toast.success(msg);
            } else {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//clearCartItemsList
export const clearCartItemsList = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.loadCartItems({
            cart_list_items_monthly: [],
            cart_list_items_yearly: [],
            yearlyDiscountPercent: 10,
            yearlyBillToday: 0,
            monthlyBillToday: 0
        }));
    };
};

export const getCartDetails = (
    token: string,
    sub_id?: number,
    clearCart?: boolean
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.get(
                `/api-subscriptions/temp-cart-item${sub_id ? `?sub_id=${sub_id}${clearCart ? `&clear_cart=true` : ''}` : clearCart ? '?clear_cart=true' : ''
                }`,
                config
            );
            const { status, msg, cart_list_items_monthly, cart_list_items_yearly, yearly_discount_percent, yearly_bill_today, monthly_bill_today } = res.data;

            if (status === 200) {
                if (!clearCart && (!cart_list_items_monthly || cart_list_items_monthly.length === 0 || !cart_list_items_yearly || cart_list_items_yearly.length === 0)) {
                    dispatch(hideSiteLoader());
                    return null
                }
                await dispatch(
                    loadCartItems({
                        cart_list_items_monthly,
                        cart_list_items_yearly,
                        yearlyDiscountPercent: Number(
                            yearly_discount_percent
                        ),
                        yearlyBillToday: yearly_bill_today,
                        monthlyBillToday: monthly_bill_today
                    })
                );
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
        } finally {
            dispatch(hideSiteLoader());
        }
    };
};

//createSubscription
export const createOrUpdateSubscription = (
    token: string,
    formData:
        | PreviewInvoiceLineItems
        | CreateSubscriptionFormData
        | CreateSubscriptionAlreadySubscribedFormData
        | DowngradeSeatSubscriptionFormData
        | CreateSubThroughBankAccFormData
): ThunkAction<
    Promise<CreateSubscriptionReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/subscription",
                formData,
                config
            );
            dispatch(handleSubscribingToStripeLoading(false))
            return res.data;
        } catch (err) {
            dispatch(handleSubscribingToStripeLoading(false))
            errToast(err as AxiosError);
        }
    };
};

//finalSubscription
export const finalSubscription = (
    token: string,
    formData: FinalSubscriptionFormData
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            await axios.post(
                "/api-subscriptions/finish-subscription",
                formData,
                config
            );
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};

//getStateList
export const getStateList = (
    token: string
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.get(`/api-user/get-state`, config);
            const { status, msg, data } = res.data;

            if (status === 200) {
                if (Array.isArray(data)) {
                    dispatch(
                        cartSelectBasinCountyActions.loadStateOptions(
                            data.map((item) => ({
                                label: item.state_name,
                                value: item.iso_code,
                            }))
                        )
                    );
                }
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};
//handleCalculateTaxLoading
export const handleCalculateTaxLoading = (
    val: CartSelectBasinCountyInitialValue["calculateTaxLoading"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.handleCalculateTaxLoading(val));
    };
};

//handleCalculateTaxLoading
export const handleSubscribingToStripeLoading = (
    val: CartSelectBasinCountyInitialValue["subscribingToStripeLoading"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.handleSubscribingToStripeLoading(val));
    };
};

//calculateTax
export const calculateTax = (
    token: string,
    formData: CalculateTaxFormData
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(handleCalculateTaxLoading(true))
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/calculate-tax",
                {
                    ...formData,
                },
                config
            );
            const { status, msg, data, saved_card, is_promotion_active } =
                res.data;
            if (status === 200) {
                if (Array.isArray(saved_card)) {
                    dispatch(
                        cartSelectBasinCountyActions.loadSavedCardDetails(
                            saved_card
                        )
                    );
                }
                if (is_promotion_active === "True") {
                    dispatch(handlePromotionCodeActive(true));
                }
                if (Object.keys(data).length > 0) {
                    dispatch(
                        cartSelectBasinCountyActions.loadCartItemTotalTax({
                            totalTax: data.tax_amount_exclusive / 100,
                            tax_percentage: Number(
                                data?.tax_breakdown[0].tax_rate_details
                                    ?.percentage_decimal
                            ),
                        })
                    );
                } else {
                    dispatch(
                        cartSelectBasinCountyActions.loadCartItemTotalTax({
                            totalTax: data,
                            tax_percentage: 0,
                        })
                    );
                }
            } else {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            dispatch(handleCalculateTaxLoading(false))
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            dispatch(handleCalculateTaxLoading(false))
            errToast(err as AxiosError);
        }
    };
};

//clearCartItemsTotalTax
export const clearCartItemsTotalTax = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.clearCartItemsTotalTax());
    };
};

//clearSavedCardDetails
export const clearSavedCardDetails = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.clearSavedCardDetails());
    };
};

//fetchSuggestionWellName
export const fetchSuggestionWellName = (
    token: string,
    formData: { wellName: string }
): ThunkAction<
    Promise<FetchWellNameSugReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        const { wellName } = formData;
        await tokenIsValid(token);
        try {
            if (abortController) {
                abortController.abort();
            }
            abortController = new AbortController();
            const signal = abortController.signal;
            const newConfig = { ...config, signal: signal };
            const res = await axios.get(
                `/api-search/search-well-api?param=${wellName}`,
                newConfig
            );

            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};

//get the csv api list after the completing the payment
export const getApiListAfterCompletingThePayment = (
    token: string,
    fileName: string
): ThunkAction<
    Promise<getApiListAfterSubReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.get(
                `api-search/refresh-csv-api?file_name=${fileName}`,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handleLastCountyBasinName
export const handleLastCountyBasinName = (
    val: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.handleLastCountyBasinName(val));
    };
};

//proceed to checkout when price is equal to or greater than basin price
export const removeMultiCountyAndBasin = (
    token: string,
    formData: {
        item_id: number[];
        item_type: 1 | 2;
        basin_name: string;
        billing_interval: string;
    }
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        const config = {
            data: {
                ...formData,
            },
            headers: { "Content-Type": "application/json" },
        };
        try {
            const res = await axios.delete(
                "/api-subscriptions/auto-update-basin",
                config
            );
            const { status, msg } = res.data;
            if (status === 200) {
                dispatch(getCartDetails(token));
            } else {
                toast.error(msg);
                dispatch(hideSiteLoader());
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};
//handle monthly and yearly tab
export const handleYearlyAndMonthlyTab = (
    val: CartSelectBasinCountyInitialValue["yearly"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.handleYearlyAndMonthlyTab(val));
    };
};

//handlePromotionCodeActive
export const handlePromotionCodeActive = (
    val: CartSelectBasinCountyInitialValue["is_promotion_active"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.handlePromotionCodeActive(val));
    };
};

export const setInvoiceLineItemsAndTotal = (
    val: InvoiceLineItemsAndTotal
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.setInvoiceLineItemsAndTotal(val));
    };
};
export const handleCartModified = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartSelectBasinCountyActions.handleCartModified(val));
    };
};

//validate promo code
export const validatePromoCode = (
    token: string,
    formData: { promo_code: string; cartTotal: number }
): ThunkAction<Promise<PromoCodeReturn>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/validate-promocode",
                { promo_code: formData["promo_code"] },
                config
            );
            const {
                status,
                msg,
                discount_fixed,
                discount_percent,
                minimum_amount,
                is_valid,
            } = res.data;
            if (status === 200) {
                if (
                    is_valid === "Valid" &&
                    minimum_amount &&
                    formData["cartTotal"] < minimum_amount
                ) {
                    toast.info(
                        `Minimum amount of ${formatUSDCurrency(
                            minimum_amount
                        )} is required to redeem this Promotion Code into a Coupon.`
                    );
                } else {
                    dispatch(
                        cartSelectBasinCountyActions.handlePromoCodeDetails({
                            ...(is_valid && { is_valid }),
                            ...(discount_fixed && { discount_fixed }),
                            ...(discount_percent && { discount_percent }),
                            ...(minimum_amount && { minimum_amount }),
                            promoCode: formData.promo_code,
                        })
                    );
                }
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};
