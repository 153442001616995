import React from "react";
// import GoNational from "./GoNational";

function BasinTabContent({ freeTrial = false }: { freeTrial?: boolean }) {
    return (
        <>
            <div className="basin-block">
                <div className="basin-circle">
                    {/* <img src="images/basin-icon.svg" alt="" /> */}
                    <img src="images/free-trial.svg" alt="" />
                </div>
                {/* <h3>10 Day Free Trial</h3> */}
                <div className="block-text-title">Select Your Basin</div>
                {/* <p className={`${freeTrial ? "" : "d-none"}`}>
                    Unlock access to detailed oil and gas data for 7 days by selecting a basin. Your trial includes all counties within your chosen basin.
                </p>
                <a
                    href="void:(0)"
                    onClick={(e) => e.preventDefault()}
                    className={`${freeTrial ? "" : "d-none"}`}
                >
                    Learn more about selecting your selection options{" "}
                    <i className="fa-solid fa-angle-right"></i>
                </a> */}
            </div>
            {/* <GoNational/> */}
            {/* <div className="gonatinalAccess">
            <div className="doller">
                <div className="graph">
                    <img src="images/s-graph.svg" alt="" />
                </div>
                <span>$999</span>
            </div>
            <div className="dollerInfo">
                <h3>Go National!<span>Get Nationwide Data Access</span></h3>
                <p>Enhance your analytics and save with Nationwide Data Access. Broaden your perspective, compare regions, and secure confident decision-making across all states.</p>
                <button className="btn btn-green width100">Get Nationwide Acccess</button>
            </div>
        </div> */}
        </>
    );
}

export default BasinTabContent;
