import React, { useEffect, useState } from "react";
import GlobalTable from "../GlobalTable";
import useWellsAndRigsCol from "./useWellsAndRigsCol";
import { GlobalTableProps } from "../../models/page-props";
import { Scrollbars } from "react-custom-scrollbars";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
    clearPermitData,
    clearProductionData,
    clearRigsData,
    clearWellsData,
    handlePageChange,
    setDontUpdateSelectedWellsRigs,
} from "../../store/actions/wells-rigs-action";
import Spinner from "../Spinner";
import LazyLoad from "react-lazy-load";
import { LINE_CHART_MAX_ITEM_ALLOWED, wells } from "../../../utils/helper";

// onlyCheckedData is used to
function TabCont({ onlyCheckedData }: { onlyCheckedData?: boolean }) {
    const wellsAndRigs = useAppSelector(state => state.wellsAndRigs);
    const productionData = useAppSelector(state => state.wellsAndRigs.productionData);
    const permitsData = useAppSelector(state => state.wellsAndRigs.permitsData);
    const rigsData = useAppSelector(state => state.wellsAndRigs.rigsData);
    const wellsData = useAppSelector(state => state.wellsAndRigs.wellsData);

    const dispatch = useAppDispatch();
    const [state, setState] = useState<{
        totalPage: number;
        nextPageDataLoading: boolean;
        page: number;
    }>({
        totalPage: 1,
        nextPageDataLoading: false,
        page: 1,
    });
    const { totalPage, nextPageDataLoading, page } = state;
    const onPageChange = () => {
        switch (wellsAndRigs.tabIndex) {
            case 0:
                dispatch(setDontUpdateSelectedWellsRigs(true))
                dispatch(handlePageChange(wellsAndRigs.wellsPage + 1));
                dispatch(clearWellsData());
                break;
            case 1:
                dispatch(handlePageChange(wellsAndRigs.rigsPage + 1));
                dispatch(clearRigsData());
                break;
            case 2:
                dispatch(handlePageChange(wellsAndRigs.permitsPage + 1));
                dispatch(clearPermitData());
                break;
            case 3:
                dispatch(handlePageChange(wellsAndRigs.productionPage + 1));
                dispatch(clearProductionData());
                break;
            default:
                dispatch(handlePageChange(wellsAndRigs.wellsPage + 1));
                dispatch(clearWellsData());
        }
    };
    useEffect(() => {
        if (
            (Array.isArray(wellsData.data) && wellsData.data.length > 0) ||
            (Array.isArray(rigsData.data) && rigsData.data.length > 0) ||
            (Array.isArray(permitsData.data) && permitsData.data.length > 0) ||
            (Array.isArray(productionData.data) && productionData.data.length > 0)
        ) {
            setState((prev) => ({
                ...prev,
                totalPage:
                    wellsAndRigs.tabIndex === 0
                        ? Math.floor(wellsData.total_count / wellsData.page_size) +
                        (wellsData.total_count % wellsData.page_size > 0 ? 1 : 0)
                        : wellsAndRigs.tabIndex === 1
                            ? Math.floor(rigsData.total_count / rigsData.page_size) +
                            (rigsData.total_count % rigsData.page_size > 0 ? 1 : 0)
                            : wellsAndRigs.tabIndex === 2 ? Math.floor(permitsData.total_count / permitsData.page_size) +
                                (permitsData.total_count % permitsData.page_size > 0 ? 1 : 0) : Math.floor(productionData.total_count / productionData.page_size) +
                            (productionData.total_count % productionData.page_size > 0 ? 1 : 0),
                nextPageDataLoading: false,
                page:
                    wellsAndRigs.tabIndex === 0
                        ? wellsAndRigs.wellsPage
                        : wellsAndRigs.tabIndex === 1
                            ? wellsAndRigs.rigsPage
                            : wellsAndRigs.tabIndex === 2 ? wellsAndRigs.permitsPage : wellsAndRigs.productionPage,
            }));
        } else {
            setState((prev) => ({
                ...prev,
                totalPage: 1,
                nextPageDataLoading: false,
                page: 1,
            }));
        }
        // eslint-disable-next-line
    }, [wellsData.data, rigsData.data, permitsData.data, wellsAndRigs.tabIndex, productionData.data]);

    return (
        <div className="searchList scrollSection">
            <LazyLoad>
                <>
                    <Scrollbars
                        className={`${nextPageDataLoading
                            ? "customTable lead-scroll no-z-index spinnerdiv "
                            : ""
                            } customTable lead-scroll no-z-index`}
                        style={{ width: "100%" }}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={`${wellsAndRigs.fullScreen
                            ? "calc(100vh - 8rem)"
                            : wellsAndRigs.resizableHeight
                            } `}
                        // autoHeightMax="25vh"
                        renderThumbVertical={(props) => (
                            <div {...props} className="thumb-vertical" />
                        )}
                        renderTrackVertical={(props) => (
                            <div {...props} className="track-vertical" />
                        )}
                        renderThumbHorizontal={(props) => (
                            <div {...props} className="thumb-horizontal" />
                        )}
                        onScrollFrame={(event) => {
                            const {
                                top,
                                scrollTop,
                                clientHeight,
                                scrollHeight,
                            } = event;
                            if (
                                top &&
                                Math.round(scrollTop + clientHeight) >=
                                Math.round((scrollHeight * 50) / 100) &&
                                !nextPageDataLoading &&
                                page < totalPage && !wellsAndRigs.fullScrnAnalytics
                            ) {
                                setState((prev) => ({
                                    ...prev,
                                    nextPageDataLoading: true,
                                }));
                                onPageChange();
                            }
                        }}
                    >
                        <GlobalTable
                            tableStyle={{
                                border: 0,
                                cellPadding: 0,
                                cellSpacing: 0,
                            }}
                            cols={
                                useWellsAndRigsCol() as GlobalTableProps["cols"]
                            }
                            loadingMsg={"Loading..."}
                            rowId={wellsAndRigs.tabIndex === 0 ? (wellsAndRigs.selectedRowId as string) : (wellsAndRigs.selectedRowId as number)}
                            data={
                                onlyCheckedData ? wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? [...wellsAndRigs.selectedWellRigOrPermitList].slice(0, LINE_CHART_MAX_ITEM_ALLOWED) : wellsAndRigs.selectedWellRigOrPermitList : Array.isArray(wellsData.data) ||
                                    Array.isArray(rigsData.data) ||
                                    Array.isArray(permitsData.data) || Array.isArray(productionData.data)
                                    ? wellsAndRigs.tabIndex === 0
                                        ? // ? wellsAndRigs.fullScreen
                                        wellsData.data
                                        : // : wellsData.data.slice(0, 3)
                                        wellsAndRigs.tabIndex === 1
                                            ? // ? wellsAndRigs.fullScreen
                                            rigsData.data
                                            : // : rigsData.data.slice(0, 3)
                                            // : wellsAndRigs.fullScreen
                                            wellsAndRigs.tabIndex === 2 ? permitsData.data : productionData.data
                                    : (null as any)
                                // ? permitsData.data
                                // : permitsData.data.slice(0, 3)
                            }
                            graySelected={wellsAndRigs.viewAnalytics}
                            showColGroup={true}
                        />
                        {/* {nextPageDataLoading && <Spinner />} */}
                    </Scrollbars>
                    {nextPageDataLoading && <Spinner />}
                </>
            </LazyLoad>
        </div>
    );
}

export default TabCont;
