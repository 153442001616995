import React from 'react'
import { Button } from "react-bootstrap";
import GlobalModal from "../GlobalModal";
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { handleFreeTrialDownAlertMsgModal } from '../../store/actions/modal-actions';
import { endFreeTrialSubscription, handleUpgradeSubscription } from '../../store/actions/subscription-settings-actions';
import { useNavigate } from 'react-router-dom';
import { cartSelectBasin } from '../../../utils/helper';



const FreeTrialDownAlertMsgModal = (props: {}) => {

    const freeTrialDownAlertMsgModal = useAppSelector(state => state.modal.freeTrialDownAlertMsgModal);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const handleClose = () => {
        dispatch(handleFreeTrialDownAlertMsgModal(false));
    }

    const endSubsBtnClick = () => {
        dispatch(handleFreeTrialDownAlertMsgModal(false));
        dispatch(endFreeTrialSubscription()).then(res => {
            const { status } = res;
            if (status === 200) {
                dispatch(handleUpgradeSubscription(true))
                navigate(cartSelectBasin);
            }
        })
    }

    return (
        <GlobalModal
            show={freeTrialDownAlertMsgModal}
            enableFooter={true}
            center={true}
            onHide={handleClose}
            contentClass={"confirmModal"}
            footerClass={"action-footer"}
            footRender={
                <>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={endSubsBtnClick}>
                        End Trial Period
                    </Button>
                </>
            }
        >
            <p>
                Downloads are limited to paid subscriptions. To enable downloading, please select End Trial Period below and select a subscription.
            </p>

        </GlobalModal >
    );
};

export default FreeTrialDownAlertMsgModal;
