import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { cartBasinState } from "../../models/redux-models";

const initialState: cartBasinState = {
    // well_status_list: [],
    // operator_list: [],
    state_list: [{ "value": "AL", "label": "Alabama" },
    { "value": "AK", "label": "Alaska" },
    { "value": "AZ", "label": "Arizona" },
    { "value": "AR", "label": "Arkansas" },
    { "value": "CA", "label": "California" },
    { "value": "CO", "label": "Colorado" },
    { "value": "CT", "label": "Connecticut" },
    { "value": "DE", "label": "Delaware" },
    { "value": "FL", "label": "Florida" },
    { "value": "GA", "label": "Georgia" },
    { "value": "HI", "label": "Hawaii" },
    { "value": "ID", "label": "Idaho" },
    { "value": "IL", "label": "Illinois" },
    { "value": "IN", "label": "Indiana" },
    { "value": "IA", "label": "Iowa" },
    { "value": "KS", "label": "Kansas" },
    { "value": "KY", "label": "Kentucky" },
    { "value": "LA", "label": "Louisiana" },
    { "value": "ME", "label": "Maine" },
    { "value": "MD", "label": "Maryland" },
    { "value": "MA", "label": "Massachusetts" },
    { "value": "MI", "label": "Michigan" },
    { "value": "MN", "label": "Minnesota" },
    { "value": "MS", "label": "Mississippi" },
    { "value": "MO", "label": "Missouri" },
    { "value": "MT", "label": "Montana" },
    { "value": "NE", "label": "Nebraska" },
    { "value": "NV", "label": "Nevada" },
    { "value": "NH", "label": "New Hampshire" },
    { "value": "NJ", "label": "New Jersey" },
    { "value": "NM", "label": "New Mexico" },
    { "value": "NY", "label": "New York" },
    { "value": "NC", "label": "North Carolina" },
    { "value": "ND", "label": "North Dakota" },
    { "value": "OH", "label": "Ohio" },
    { "value": "OK", "label": "Oklahoma" },
    { "value": "OR", "label": "Oregon" },
    { "value": "PA", "label": "Pennsylvania" },
    { "value": "RI", "label": "Rhode Island" },
    { "value": "SC", "label": "South Carolina" },
    { "value": "SD", "label": "South Dakota" },
    { "value": "TN", "label": "Tennessee" },
    { "value": "TX", "label": "Texas" },
    { "value": "UT", "label": "Utah" },
    { "value": "VT", "label": "Vermont" },
    { "value": "VA", "label": "Virginia" },
    { "value": "WA", "label": "Washington" },
    { "value": "WV", "label": "West Virginia" },
    { "value": "WI", "label": "Wisconsin" },
    { "value": "WY", "label": "Wyoming" }],
    // county_list: [],
    // basin_list: [],
    basinSearchList: [],
    countySearchList: [],
    wellApiListAfterCsvUpload: [],
    sliderMinMaxValue: {
        measured_depth: 40000,
        true_vertical_depth: 30000,
        lateral_length: 30000,
        minMeasuredDepth: 0,
        minTrueVerticalDepth: 0,
        minLateralLength: 0,
        maxMeasuredDepth: 40000,
        maxTrueVerticalDepth: 30000,
        maxLateralLength: 30000,
        dataLoading: true,
    },
    leftTab: true,
    clearAllFilter: false,
    showAdvancedFilter: false,
    savedFilter: {
        openSavedFilter: false,
        saveFiltersDataLoading: true,
        filterID: 0,
        saveFiltersList: null,
    },
    savedFilterColumnProperties: {
        openSavedFilterColumnProperties: false,
        saveFiltersDataLoadingColumnProperties: false,
        filterIDColumnProperties: '-1',
    },
};

export const cartBasinToCountySlice = createSlice({
    name: "cartbasin",
    initialState,
    reducers: {
        clearSearchList(state, action: PayloadAction) {
            return {
                ...state,
                basinSearchList: [],
                countySearchList: [],
            };
        },
        fetchBasinSearchList(
            state,
            action: PayloadAction<cartBasinState["basinSearchList"]>
        ) {
            return {
                ...state,
                countySearchList: [],
                basinSearchList: action.payload,
            };
        },
        handleWellApiListAfterCsvUpload(
            state,
            action: PayloadAction<cartBasinState["wellApiListAfterCsvUpload"]>
        ) {
            return {
                ...state,
                wellApiListAfterCsvUpload: action.payload,
            };
        },
        handleSliderValue(
            state,
            action: PayloadAction<cartBasinState["sliderMinMaxValue"]>
        ) {
            return {
                ...state,
                sliderMinMaxValue: {
                    ...state.sliderMinMaxValue,
                    ...action.payload,
                },
            };
        },
        toggleLeftTab(state, action: PayloadAction<cartBasinState["leftTab"]>) {
            return {
                ...state,
                leftTab: action.payload,
            };
        },
        handleClearAllFilter(
            state,
            action: PayloadAction<cartBasinState["clearAllFilter"]>
        ) {
            return {
                ...state,
                clearAllFilter: action.payload,
            };
        },
        handleAdvancedFilter(
            state,
            action: PayloadAction<cartBasinState["showAdvancedFilter"]>
        ) {
            return {
                ...state,
                showAdvancedFilter: action.payload,
            };
        },
        handleSavedFilter(
            state,
            action: PayloadAction<{
                openSavedFilter?: cartBasinState["savedFilter"]["openSavedFilter"];
                saveFiltersDataLoading?: cartBasinState["savedFilter"]["saveFiltersDataLoading"];
                saveFiltersList?: cartBasinState["savedFilter"]["saveFiltersList"];
                filterID?: cartBasinState["savedFilter"]["filterID"];
                reset?: boolean;
            }>
        ) {
            return {
                ...state,
                savedFilter: {
                    ...state.savedFilter,
                    ...("openSavedFilter" in action.payload && {
                        openSavedFilter: action.payload.openSavedFilter,
                    }),
                    ...("saveFiltersDataLoading" in action.payload && {
                        saveFiltersDataLoading:
                            action.payload.saveFiltersDataLoading,
                    }),
                    ...("saveFiltersList" in action.payload && {
                        saveFiltersList: action.payload.saveFiltersList,
                    }),
                    ...("reset" in action.payload && {
                        ...initialState["savedFilter"],
                    }),
                    ...("filterID" in action.payload && {
                        filterID: action.payload.filterID,
                    }),
                },
            };
        },
        handleSavedFilterColumnProperties(
            state,
            action: PayloadAction<{
                openSavedFilter?: cartBasinState["savedFilterColumnProperties"]["openSavedFilterColumnProperties"];
                saveFiltersDataLoading?: cartBasinState["savedFilterColumnProperties"]["saveFiltersDataLoadingColumnProperties"];
                filterID?: cartBasinState["savedFilterColumnProperties"]["filterIDColumnProperties"];
                reset?: boolean;
            }>
        ) {
            return {
                ...state,
                savedFilterColumnProperties: {
                    ...state.savedFilterColumnProperties,
                    ...("openSavedFilter" in action.payload && {
                        openSavedFilterColumnProperties: action.payload.openSavedFilter,
                    }),
                    ...("saveFiltersDataLoading" in action.payload && {
                        saveFiltersDataLoadingColumnProperties:
                            action.payload.saveFiltersDataLoading,
                    }),
                    ...("reset" in action.payload && {
                        ...initialState["savedFilterColumnProperties"],
                    }),
                    ...("filterID" in action.payload && {
                        filterIDColumnProperties: action.payload.filterID,
                    }),
                },
            };
        },
    },
});

export default cartBasinToCountySlice;
