import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
    handleResizableWidth,
    handleShowAndHideTable,
    handleTabIndex,
    resetWellsAndRigsSliceToInitial,
    handleSelectedWellRigOrPermitUIDList,
} from "../../store/actions/wells-rigs-action";
import { ANALYTICS_DEFAULT_WIDTH, numberFormat } from '../../../utils/helper';
import Filter from "./Filter";
// import { handleClearAllFilter } from "../../store/actions/cart-basin-to-county-actions";

function TabHeadingSec() {
    const dispatch = useAppDispatch();

    const fullScrnAnalytics = useAppSelector(state => state.wellsAndRigs.fullScrnAnalytics);
    const tabIndex = useAppSelector(state => state.wellsAndRigs.tabIndex);
    const wells_total_count = useAppSelector(state => state.wellsAndRigs.wellsData.total_count);
    const rigs_total_count = useAppSelector(state => state.wellsAndRigs.rigsData.total_count);

    const tabHeading: { label: string; count: number; tab_index: 0 | 1 | 2 | 3 }[] =
        [
            {
                label: "Wells",
                count: wells_total_count,
                tab_index: 0,
            },
            // {
            //     label: "Permits",
            //     count: permits_total_count,
            //     tab_index: 2,
            // },
            // {
            //     label: "Production",
            //     count: production_total_count,
            //     tab_index: 3,
            // },
            {
                label: "Rigs",
                count: rigs_total_count,
                tab_index: 1,
            },
        ];

    return (
        <div className={`search-header ${fullScrnAnalytics ? "tableSearchHeaderTop" : ""}`}>
            <div className="search-top">
                <div className="searchByTab">
                    <ul className="nav" id="myTab" role="tablist">
                        {tabHeading.map((item, index) => {
                            const { label, count, tab_index } = item;
                            return (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={index}
                                    onClick={() => {
                                        if (tabIndex !== tab_index) {
                                            dispatch(handleTabIndex(tab_index));
                                            dispatch(handleSelectedWellRigOrPermitUIDList([]))
                                            dispatch(
                                                resetWellsAndRigsSliceToInitial(
                                                    true
                                                )
                                            );
                                        }
                                    }}
                                >
                                    <button
                                        className={`nav-link ${tab_index === tabIndex ? "active" : ""
                                            }`}
                                        id={`${label.toLowerCase()}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#${label.toLowerCase()}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`${label.toLowerCase()}`}
                                        aria-selected={
                                            index === 0 ? "true" : "false"
                                        }
                                    >
                                        {label}{" "}
                                        {/* {count !== 0 && <span>{count}</span>} */}
                                        <span>{numberFormat.format(count)}</span>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="clearfilterright">
                    <Filter hideAdvFilterBtn={true} hideTableFilters={fullScrnAnalytics ? true : false} />
                </div>
            </div>
        </div>
    );
}

export default TabHeadingSec;
