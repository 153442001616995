import React from "react";
// import GoNational from "./GoNational";

function CountyTabContent({ freeTrial = false }: { freeTrial?: boolean }) {
    return (
        <>
            <div className="basin-block">
                <div className="basin-circle">
                    {/* <img src="images/basin-icon.svg" alt="" /> */}
                    <img src="images/free-trial.svg" alt="" />
                </div>
                <div className="block-text-title">Select Your County</div>
                {/* <p className={`${freeTrial ? "" : "d-none"}`}>
                    Unlock access to detailed oil and gas data for 7 days by selecting a county. Your trial includes all counties within your chosen county.
                </p>
                <a
                    href="void:(0)"
                    onClick={(e) => e.preventDefault()}
                    className={`${freeTrial ? "" : "d-none"} learn-more`}
                >
                    Learn more about selecting your selection options{" "}
                    <i className="fa-solid fa-angle-right"></i>
                </a> */}
            </div>
            {/* <GoNational /> */}
        </>

    );
}

export default CountyTabContent;
