import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../../models/redux-models"

export function isInstanceOfProductionDataObj(object: any): object is ProductionDataObj {
    if (typeof object !== 'object') {
        return false
    }
    return 'Production Month' in object
}

export function isInstanceOfRigsDataObj(object: any): object is RigsDataObj {
    if (typeof object !== 'object') {
        return false
    }
    return 'driller' in object
}

export function isInstanceOfWellsAndPermitsObject(object: any): object is WellsAndPermitsObject {
    if (typeof object !== 'object') {
        return false
    }
    return 'well_type_name' in object
}